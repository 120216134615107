import '@babel/polyfill';
import 'styles/main';
import 'utilities/detect-ie';
import 'dayjs/locale/da';
import { WebshopConfig } from "webshop/config";
import Vue from 'vue';
import 'modules/donation/Component';
import App from 'components/App.vue';
import VueRouter from 'vue-router';
import vueDebounce from 'vue-debounce';
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);
Vue.use(vueDebounce, {
    listenTo: ['input', 'keyup']
});
Vue.use(VueRouter);
// Set base
let baseUrl = "/";
if (WebshopConfig && WebshopConfig.baseUrl) {
    baseUrl = WebshopConfig.baseUrl;
}
const router = new VueRouter({
    base: baseUrl,
    routes: [
        {
            path: '/', component: App, children: App.prototype.routes, props: true
        }
    ],
    mode: 'history'
});
new Vue({
    el: '#app',
    router,
    components: {
        App
    }
});
