import { Service } from '@jcd-web/basic-service';
import { EventApi } from '../api/EventApi';
import { State } from 'config/State';
export class EventService extends Service {
    static getlist() {
        return EventApi.getlist(State.currentId, State.language).then(r => {
            return r.data.map(e => e);
        });
    }
}
