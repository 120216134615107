var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "button primary contained large",
        class: { disabled: _vm.downloading },
        attrs: { disabled: _vm.downloading },
        on: {
          click: function($event) {
            return _vm.download()
          }
        }
      },
      [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm._f("translate")("WebshopTicketDownloadText")) +
            "\n\n\t\t"
        ),
        _vm.downloading ? _c("loader", { attrs: { size: "small" } }) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }