import Vue from 'vue';
import dayjs from 'dayjs';
Vue.filter('formatDate', (value) => {
    return dayjs(value).format('L');
});
Vue.filter('formatDateUTC', (value) => {
    return dayjs(value).format('YYYY-MM-DD');
});
Vue.filter('formatDateAndHour', (value) => {
    return dayjs(value).format('L LT');
    // 09/04/1986 + 8:30 PM
});
Vue.filter('formatHour', (value) => {
    return dayjs(value).format('LT');
    // return moment(value).format('HH:mm');
    // 8:30 PM
});
Vue.filter('formatDateWithWeekday', (value) => {
    return dayjs(value).format('dddd LL');
    // return moment(value).format('dddd D. MMMM YYYY');
    // Monday d. September 4, 1986 ? Ved ikke helt om dette giver det ønskede output
});
Vue.filter('formatLongDate', (value) => {
    return dayjs(value).format('LL');
    // return moment(value).format('D. MMMM YYYY');
    // 
});
Vue.filter('formatWeekday', (value) => {
    return dayjs(value).format('dddd');
});
Vue.filter('weekdayShorthand', (value) => {
    return dayjs(value).format('ddd');
});
Vue.filter('formatDateDay', (value) => {
    return dayjs(value).format('DD');
});
Vue.filter('formatDateMonth', (value) => {
    return dayjs(value).format('MMMM');
});
Vue.filter('formatDateShortMonth', (value) => {
    return dayjs(value).format('MMM');
});
Vue.filter('formatDateYear', (value) => {
    return dayjs(value).format('YYYY');
});
