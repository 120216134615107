var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let CheckoutLogin = class CheckoutLogin extends Vue {
    constructor() {
        super(...arguments);
        this.showModal = false;
    }
    get loginDescription() {
        let _translation = Vue.filter("translate")("WebshopLoginModalDescription");
        if (_translation) {
            return _translation;
        }
        return "";
    }
    toggleLogin() {
        this.showModal = true;
    }
    onClose() {
        this.showModal = false;
    }
};
CheckoutLogin = __decorate([
    Component({
        props: {},
        components: {}
    })
], CheckoutLogin);
export default CheckoutLogin;
