import { Service } from "@jcd-web/basic-service";
import { CartApi } from "../api/CartApi";
import { TrackingService } from "./TrackingService";
export default class CartService extends Service {
    static getCart() {
        return new Promise((resolve, reject) => {
            CartApi.getCart().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static getMiniCart() {
        return new Promise((resolve, reject) => {
            CartApi.getMiniCart().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static deleteCart() {
        return new Promise((resolve, reject) => {
            CartApi.deleteCart().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static createLine(cartLineInsert, trackData) {
        return new Promise((resolve, reject) => {
            CartApi.createLine(cartLineInsert).then(res => {
                const data = res.data;
                TrackingService.addToCart(data);
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static updateLine(line) {
        return new Promise((resolve, reject) => {
            CartApi.updateLine(line.guid, line).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response.data);
            });
        });
    }
    static deleteLine(guid) {
        return new Promise((resolve, reject) => {
            CartApi.deleteLine(guid).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static deleteDataLine(lineGuid, dataGuid) {
        return new Promise((resolve, reject) => {
            CartApi.deleteDataLine(lineGuid, dataGuid).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static checkout(checkout) {
        return new Promise((resolve, reject) => {
            CartApi.checkout(checkout).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static updateBillingAddress(model) {
        return new Promise((resolve, reject) => {
            CartApi.updateBillingAddress(model).then(() => {
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    }
    // Giftcard
    static addGiftCard(giftCard) {
        return new Promise((resolve, reject) => {
            CartApi.addGiftCard(giftCard).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response);
            });
        });
    }
    static removeGiftCard() {
        return new Promise((resolve, reject) => {
            CartApi.removeGiftCard().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // Promotion codes
    static addPromotionCode(promotionCode) {
        return new Promise((resolve, reject) => {
            CartApi.addPromotionCode(promotionCode).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response);
            });
        });
    }
    static removePromotionCode() {
        return new Promise((resolve, reject) => {
            CartApi.removePromotionCode().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // Shipping
    static getShippingMethods() {
        return new Promise((resolve, reject) => {
            CartApi.getShippingMethods().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static getShippingLocations(shippingGuid, request) {
        return new Promise((resolve, reject) => {
            CartApi.getShippingLocations(shippingGuid, request).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static updateShippingMethod(model) {
        return new Promise((resolve, reject) => {
            CartApi.updateShippingMethod(model).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
}
