var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language-selector", class: { visible: _vm.visible } },
    [
      _c("div", { attrs: { container: "" } }, [
        _c("div", { attrs: { grid: "nowrap" } }, [
          _c("div", { attrs: { column: "l-2 m-3 s-12" } }, [
            _c("header", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("translate")("chooseLanguage")) +
                  ":\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { column: "l-8 m-8 s-12" } },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _c("div", { attrs: { column: "l-2 m-2 s-12 l-_text-right" } }, [
            _c(
              "a",
              {
                staticClass: "close-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("translate")("closeLangaugeSelect")))
                ]),
                _vm._v(" "),
                _c("i", { staticClass: "mdi mdi-close" })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }