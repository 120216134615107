import { Api } from "@jcd-web/basic-api";
export class UserApi extends Api {
    static create(createUser) {
        return UserApi.client.post("", createUser);
    }
    static get() {
        return UserApi.client.get("me");
    }
    static update(updateUser) {
        return UserApi.client.put("me", updateUser);
    }
    static changePassword(changePassword) {
        return UserApi.client.patch("me/password", changePassword);
    }
    static updatePreferences(model) {
        return UserApi.client.put("me/preferences", model);
    }
    static login(model) {
        return UserApi.client.post("me/login", model);
    }
    static logout() {
        return UserApi.client.post("me/logout");
    }
    static activate(guid, model) {
        return UserApi.client.post(guid + "/activate", model);
    }
    static resetPassword(guid, model) {
        return UserApi.client.post(guid + "/reset-password", model);
    }
    static requestResetPassword(model) {
        return UserApi.client.post("request-password-reset", model);
    }
    // User Admission Cards
    static getMyAdmissionCards() {
        return UserApi.client.get('me/admission-cards');
    }
    static renewAdmissionCard(cardId) {
        return UserApi.client.post(`me/admission-cards/${cardId}/renew`);
    }
}
UserApi.baseUrl = "/api/users/";
