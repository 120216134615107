var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import * as _ from "lodash";
import Component from "vue-class-component";
import { ToastService } from "@jcd-web/basic-toast";
import { ErrorService } from "../../services/ErrorService";
import WebshopEvents, { WebshopEventTypes } from '../../utilities/WebshopEvents';
import { ProductTypes } from '../../services/ProductService';
import CartService from "../../services/CartService";
import { ProductType } from "../../models/cart/Cart";
import { ErrorCodes } from "../../models/Errors";
import CartLineAdmissionCard from './CartLineAdmissionCard.vue';
import EventTime from '../tickets/EventTime.vue';
let CartLine = class CartLine extends Vue {
    constructor() {
        super(...arguments);
        this.quantity = _.clone(this.item.quantity);
        this.currentQuantity = _.clone(this.quantity);
        this.mediaItem = null;
        this.debounceQuantity = this.quantity;
        this.loading = false;
        // Her skal laves markering af linjer der er ens. Opgaven afventer - PJH
        // handleTicketStock(ticketId: string) {
        //   if (ticketId === this.item.productId) {
        //
        //   }
        // }
    }
    get eventDate() {
        const _startDate = this.item.data && this.item.data.startDate ? this.item.data.startDate : null;
        const _end = this.item.data && this.item.data.endTime ? this.item.data.endTime : null;
        const _start = this.item.data && this.item.data.startTime ? this.item.data.startTime : null;
        let dateTime = {
            start: _start,
            startDate: _startDate,
            end: _end
        };
        return dateTime;
    }
    get media() {
        let path = "";
        if (this.mediaItem) {
            if (this.mediaItem.croppings && this.mediaItem.croppings["1:1"]) {
                path = this.mediaItem.croppings["1:1"];
            }
            else {
                path = this.mediaItem.url;
            }
        }
        return path;
    }
    get capacity() {
        let defaultValue = 1000; // default
        let _item = _.clone(this.item);
        let capacity = _item.data && _item.data.availableCapacity ? _item.data.availableCapacity : defaultValue;
        return capacity;
    }
    get icon() {
        let _type = this.item.data?.type;
        if (_type) {
            if (_type === ProductTypes.Ticket) {
                return "mdi-ticket-confirmation-outline";
            }
            else if (_type === ProductTypes.GiftCard) {
                return "mdi-wallet-giftcard";
            }
            else if (_type === ProductTypes.AdmissionCard) {
                return "mdi-card-account-details-outline";
            }
        }
        return "";
    }
    get enableQuantity() {
        /*
          Fjern mulighed for at redigere antal ifm. medlemskort, da de ikke kan tilføjes
          eller ændres direkte fra checkout
        */
        if (this.item.productType === ProductType.AdmissionCard) {
            return false;
        }
        return true;
    }
    get isAdmissionCard() {
        if (this.item.productType === ProductType.AdmissionCard) {
            return true;
        }
        return false;
    }
    get itemQuantity() {
        return this.item.quantity;
    }
    setValue() {
        if (!this.quantity) {
            this.quantity = this.currentQuantity;
        }
    }
    update(e) {
        this.loading = true;
        let _item = _.clone(this.item);
        _item.quantity = this.quantity;
        // Slet linje hvis værdi bliver sat til 0
        // Ignorer "Slet linje" hvis værdien er "" for at sikre linjen ikke bliver slettet af "debounce"
        let capacity = _item.data && _item.quantity > _item.data.availableCapacity ? _item.data.availableCapacity : null;
        if (!_item.quantity) {
            return;
        }
        else if (_item.quantity == 0) {
            this.remove();
        }
        else {
            // Capacity check
            // Hvis mængden overskrider kapaciteten, skal mængden nedjusteres til kapaciteten.
            if (capacity) {
                _item.quantity = capacity;
                ToastService.danger(Vue.filter("translate")("webshopErrorCodeCheckoutTicketOutOfStock"), 4000);
                // Hvis mængden skal nedjusteres til det antal der allerede ligger i kurven, er det ikke
                // nødvendigt at opdatere kurven
                if (this.currentQuantity == _item.quantity) {
                    this.quantity = this.currentQuantity;
                    this.loading = false;
                    return;
                }
            }
            CartService.updateLine(_item).then(line => {
                ToastService.success(Vue.filter("translate")("webshopCheckoutCartUpdated"), 4000);
                this.currentQuantity = _item.quantity;
                this.quantity = _item.quantity;
                this.$emit("update-line", this.item);
                this.loading = false;
            }, (err) => {
                let _err = err;
                // Rollback til currentQuantity hvis der sker fejl  
                this.quantity = this.currentQuantity;
                this.loading = false;
                if (_err.code === ErrorCodes.CheckoutTicketOutOfStock) {
                    WebshopEvents.$emit(WebshopEventTypes.TicketOutOfStock, _err.data);
                }
                else {
                    ErrorService.handleError(err.response);
                }
            });
        }
    }
    onRemoveAdmissionCard(item) {
        this.$emit("delete-line");
    }
    remove() {
        let message = Vue.filter("translate")("webshopCheckoutCartWishToDelete");
        message = message.replace("{product}", this.item.name);
        if (window.confirm(message)) {
            CartService.deleteLine(this.item.guid).then((res) => {
                this.$emit("delete-line", this.item);
                this.loading = false;
                ToastService.success(Vue.filter("translate")("webshopCheckoutCartLineDeleted"), 4000);
            }, (err) => {
                this.loading = false;
                ErrorService.handleError(err.response);
            });
        }
        else if (this.quantity == 0) {
            this.quantity = this.currentQuantity;
            this.loading = false;
            // this.update();
        }
    }
    selectValue(e) {
        if (e && e.target) {
            e.target.select();
        }
    }
};
CartLine = __decorate([
    Component({
        props: {
            item: {
                type: Object,
                default: {}
            }
        },
        components: {
            EventTime,
            CartLineAdmissionCard
        },
    })
], CartLine);
export default CartLine;
