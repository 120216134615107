import { Api } from "@jcd-web/basic-api";
export class AdmissionApi extends Api {
    static getAvailableDatesByEvent(id) {
        return AdmissionApi.client.get(id);
    }
    // api/admission/B0002;b0003/dates?year=2020&month=6
    static getAvailableDatesByAdmissionIds(ids, year, month) {
        let stringIds = ids.join(';');
        let query = year !== null && month !== null ? '?year=' + year + '&month=' + (month + 1) : '';
        return AdmissionApi.client('tickets/' + stringIds + '/dates' + query);
    }
    // static getByDate(productId: number, date: string) {
    //     return AdmissionApi.client.get(productId + '/' + date);
    // }
    // api/admission/dates/2020-06-24/tickets/B0002;B0003
    static getByDateAndAdmissionIds(ids, date) {
        let stringIds = ids.join(';');
        return AdmissionApi.client.get('dates/' + date + '/tickets/' + stringIds);
    }
    //api/admission/tickets/B0032;B0033/dates/2020-10-01
    static getTicketsByDate(tickets, date) {
        return AdmissionApi.client.get(`tickets/${tickets}/dates/${date}/events`);
    }
    static getTicketsByEvent(tickets, date, event) {
        return AdmissionApi.client.get(`tickets/${tickets}/dates/${date}/events/${event}`);
    }
    // Admission Cards
    static getAdmissionCards(ids) {
        return AdmissionApi.client.post('admission-cards', ids);
    }
    static getAdmissionCardDefinitions(ids) {
        let definitionIDs = ids.join(';');
        return AdmissionApi.client.get('/cards/definitions/' + definitionIDs);
    }
}
AdmissionApi.baseUrl = "/api/admission/";
