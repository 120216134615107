var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.eventList.length
    ? _c("section", { staticClass: "event-list section" }, [
        _c("div", { attrs: { container: "" } }, [
          _c(
            "div",
            { staticClass: "event-list-filters", attrs: { grid: "wrap" } },
            [
              _vm.getFiltersFromList().length > 1
                ? _c("label", { attrs: { column: "s-12 m-4 l-3" } }, [
                    _vm._v(
                      _vm._s(_vm._f("translate")("location")) + "\n        "
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedFilter,
                            expression: "selectedFilter"
                          }
                        ],
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedFilter = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm._f("translate")("all")))
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.getFiltersFromList(), function(
                          filter,
                          index
                        ) {
                          return _c("option", { key: index }, [
                            _vm._v(_vm._s(filter))
                          ])
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("label", { attrs: { column: "s-12 m-4 l-3" } }, [
                _vm._v(_vm._s(_vm._f("translate")("sortBy")) + "\n        "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.sortOrder,
                        expression: "sortOrder"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.sortOrder = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "dateAsc" } }, [
                      _vm._v(_vm._s(_vm._f("translate")("dateAscending")))
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "dateDesc" } }, [
                      _vm._v(_vm._s(_vm._f("translate")("dateDescending")))
                    ])
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { grid: "wrap" } },
            _vm._l(_vm.filteredEventList(), function(event, index) {
              return _c(
                "div",
                { key: index, attrs: { column: "s-12 m-6 l-4" } },
                [
                  _c("div", { staticClass: "event-card-wrapper" }, [
                    _c(
                      "a",
                      { staticClass: "event-card", attrs: { href: event.url } },
                      [
                        _c("div", {
                          staticClass: "image",
                          style: { backgroundImage: "url(" + event.image + ")" }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "data" }, [
                          event.name
                            ? _c("p", { staticClass: "title" }, [
                                _vm._v(_vm._s(event.name))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          event.manchet
                            ? _c("p", { staticClass: "manchet" }, [
                                _vm._v(_vm._s(event.manchet))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "date" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm._f("translate")("date")) + ":")
                            ]),
                            _vm._v(
                              "\n                " +
                                _vm._s(event.displayDate) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "time" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm._f("translate")("time")) + ":")
                            ]),
                            _vm._v(
                              "\n                " +
                                _vm._s(event.displayTime) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "location" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm._f("translate")("location")) + ":"
                              )
                            ]),
                            _vm._v(
                              "\n                " +
                                _vm._s(event.location) +
                                "\n              "
                            )
                          ]),
                          _vm._v(" "),
                          event.price
                            ? _c("p", { staticClass: "price" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm._f("translate")("price")) + ":"
                                  )
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(event.price) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "button-wrapper" }, [
                            _c("span", { staticClass: "goto-link" }, [
                              _vm._v(_vm._s(_vm._f("translate")("readMore")))
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }