export class MiniCart {
}
export class MiniCartMessage {
    constructor(message, icon, lines) {
        this.icon = icon;
        this.message = message;
        this.lines = lines;
    }
}
export class MiniCartMessageLine {
    constructor(name, quantity, icon, id, priceFormatted) {
        this.name = name;
        this.quantity = quantity;
        this.icon = icon;
        this.id = id;
        this.priceFormatted = priceFormatted;
    }
}
