var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { GoogleService } from "services/GoogleService";
import { DatalayerEvent } from "../models/DatalayerEvent";
import Loader from "../components/utility/Loader.vue";
let CallToAction = class CallToAction extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.success = false;
    }
    mounted() { }
    fire(event) {
        let element = event.target;
        // google event
        let href = element.href;
        let target = element.target;
        let gaId = element.dataset.ga;
        if (gaId) {
            let category = "CTA";
            let gaEvent = new DatalayerEvent();
            gaEvent.category = category;
            gaEvent.action = "click";
            gaEvent.label = (gaId !== null || gaId !== "" ? gaId + ": " : "") + href;
            // console.log(gaEvent);
            GoogleService.addAnalyticsEvent(gaEvent).then((res) => {
                window.open(href, target);
            });
        }
        else {
            window.open(href, target);
        }
    }
};
CallToAction = __decorate([
    Component({
        props: {},
        components: {
            Loader,
        },
    })
], CallToAction);
export default CallToAction;
