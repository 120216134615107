var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "modal",
                class: _vm.customClass,
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-container",
                    style: { "max-width": _vm.modalWidth },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-header" },
                      [
                        _vm._t("header"),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "modal-close-link",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_c("span", { staticClass: "mdi icon mdi-close" })]
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        style: {
                          height: _vm.modalHeight,
                          "max-height": _vm.modalMaxHeight
                        }
                      },
                      [_vm._t("body")],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [_vm._t("footer")],
                      2
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }