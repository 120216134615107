import { Api } from "@jcd-web/basic-api";
export class ProductApi extends Api {
    // static search(query: string, categoryId: number | null, page: number = 1, pageSize: number = 25) {
    // static search(data) {
    //   return this.client.post("/search/", data);
    // }
    // static getByCategoryId(categoryId: number | null, page: number, pageSize: number) {
    //   return ProductApi.client.get("", {
    //     params: {
    //       categoryId: categoryId,
    //       page: page,
    //       pageSize: pageSize
    //     }
    //   });
    // }
    // static getAll(returnType: string) {
    //   return ProductApi.client.get("get-all", {
    //     params: {
    //       returnType: returnType
    //     }
    //   });
    // }
    // static getByIds(productIds: string[]) {
    //   return ProductApi.client.get("/" + productIds.join(';'));
    // }
    static getProducts(params) {
        return ProductApi.client.get("/", { params: params });
    }
    static get(id) {
        return ProductApi.client.get("/" + id);
    }
}
ProductApi.baseUrl = "/api/products/";
