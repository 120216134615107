import { Api } from "@jcd-web/basic-api";
export class CartApi extends Api {
    static getCart() {
        return CartApi.client.get("");
    }
    static getMiniCart() {
        return CartApi.client.get("mini");
    }
    static deleteCart() {
        return CartApi.client.delete("");
    }
    static createLine(cartLineInsert) {
        return CartApi.client.post("lines", cartLineInsert);
    }
    static updateLine(lineGuid, line) {
        return CartApi.client.put("lines/" + lineGuid, line);
    }
    static deleteLine(lineGuid) {
        return CartApi.client.delete("lines/" + lineGuid);
    }
    static deleteDataLine(lineGuid, dataGuid) {
        return CartApi.client.delete(`lines/${lineGuid}/data/${dataGuid}`);
    }
    static checkout(checkout) {
        return CartApi.client.post("checkout", checkout);
    }
    // static validateBillingAddress(model: CheckoutLocation) {
    //     return CartApi.client.post("validate-billing-address", model);
    // }
    static updateBillingAddress(model) {
        return CartApi.client.put("billing", model);
    }
    // Giftcards
    static addGiftCard(giftCard) {
        return CartApi.client.patch("giftcard", giftCard);
    }
    static removeGiftCard() {
        return CartApi.client.delete("giftcard");
    }
    // Promotion codes
    static addPromotionCode(promotionCode) {
        return CartApi.client.patch("promotion-code", promotionCode);
    }
    static removePromotionCode() {
        return CartApi.client.delete("promotion-code");
    }
    // Shipping
    static getShippingMethods() {
        return CartApi.client.get("shipping");
    }
    static getShippingLocations(shippingGuid, params) {
        return CartApi.client.get(`shipping/${shippingGuid}/pickup-locations`, { params: params });
    }
    static updateShippingMethod(model) {
        return CartApi.client.put('shipping', model);
    }
}
CartApi.baseUrl = "/api/cart/";
