var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { AddressInfo } from "webshop/models/checkout/Checkout";
import CartService from "webshop/services/CartService";
import { ErrorService } from "webshop/services/ErrorService";
import { PickupLocationRequest } from "../../../models/checkout/shipping/Shipping";
let PickupLocations = class PickupLocations extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.locations = [];
        this.zip = "";
        this.street = "";
        this.guid = "";
        this.selectedParcelLocationId = "";
        this.isSelected = false;
        this.selectedAddress = null;
        this.loadPickupLocationsError = false;
    }
    selectPickupLocation(option) {
        this.selectedParcelLocationId = option.id;
        this.isSelected = true;
        // Map IPickupLocation til AddressInfo
        let _address = new AddressInfo();
        _address.name = option.name;
        _address.address = option.address;
        _address.address2 = option.address2;
        _address.zipCode = option.zipCode;
        _address.city = option.city;
        _address.countryCode = option.countryCode;
        _address.openingHours = option.openingHours;
        this.selectedAddress = _address;
        this.$emit('select', option);
    }
    search() {
        this.loading = true;
        this.loadPickupLocationsError = false;
        let data = new PickupLocationRequest();
        data.guid = this.guid;
        data.street = this.street;
        data.zip = this.zip;
        CartService.getShippingLocations(data.guid, data).then(res => {
            this.loading = false;
            this.locations = res;
        }, err => {
            this.loading = false;
            this.loadPickupLocationsError = true;
            ErrorService.handleError(err);
        });
    }
    selectAnotherParcelShop() {
        this.isSelected = false;
        this.selectedParcelLocationId = "";
        this.search();
    }
    mounted() {
        // Sæt default værdier
        this.guid = this.shippingMethod.guid;
        if (this.billingAddress) {
            this.zip = this.billingAddress.zipCode;
            this.street = this.billingAddress.address;
        }
        // Hvis der allerede er en adresse på shipping method, er det fordi der tidligere har været valgt en pakkeshop
        if (this.shippingMethod.address) {
            this.selectedAddress = this.shippingMethod.address;
            this.isSelected = true;
            this.loading = false;
        }
        // Ellers lav opslag på postnr og vej
        else {
            this.search();
        }
    }
    isPickupLocationSelected(id) {
        return this.selectedParcelLocationId === id;
    }
};
PickupLocations = __decorate([
    Component({
        components: {},
        props: {
            shippingMethod: Object,
            billingAddress: Object
        }
    })
], PickupLocations);
export default PickupLocations;
