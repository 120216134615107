/*
    Load components for user profile management:
        -   UserAdmissionCards
        -   UserMe
        -   UserProfile
        -   UserCreateProfile
        -   UserActivate
        -   UserActivateNew
*/
import Vue from 'vue';
const UserAdmissionCards = () => ({
    component: import(/* webpackChunkName: 'UserAdmissionCards' */ './UserAdmissionCards.vue')
});
Vue.component('user-admission-cards', UserAdmissionCards);
const UserMe = () => ({
    component: import(/* webpackChunkName: 'UserMe' */ './UserMe.vue')
});
Vue.component('user-me', UserMe);
const UserProfile = () => ({
    component: import(/* webpackChunkName: 'UserProfile' */ './user-profile/UserProfile.vue')
});
Vue.component('user-profile', UserProfile);
const UserCreateProfile = () => ({
    component: import(/* webpackChunkName: 'UserCreateProfile' */ './UserCreateProfile.vue')
});
Vue.component('user-create-profile', UserCreateProfile);
const UserActivate = () => ({
    component: import(/* webpackChunkName: 'UserActivate' */ './UserActivate.vue')
});
Vue.component('user-activate', UserActivate);
const UserActivateNew = () => ({
    component: import(/* webpackChunkName: 'UserActivateNew' */ './UserActivateNew.vue')
});
Vue.component('user-activate-new', UserActivateNew);
