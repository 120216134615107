var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticket-list" },
    [
      _c(
        "header",
        {
          staticClass: "ticket-configurator-header",
          class: {
            active: _vm.activeState,
            "ticket-configurator-header--sold-out": _vm.isSoldOut
          }
        },
        [
          _c("span", {
            staticClass: "mdi header-icon mdi-ticket-confirmation"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ticket-configurator-header__content" }, [
            _c("div", { staticClass: "title" }, [
              _vm.pickTicketsLabel
                ? _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.pickTicketsLabel) +
                        "\n        "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.step) +
                        ". " +
                        _vm._s(
                          _vm._f("translate")("webshopTicketChooseTickets")
                        ) +
                        "\n        "
                    )
                  ])
            ]),
            _vm._v(" "),
            _vm.isSoldOut
              ? _c("div", { staticClass: "title" }, [
                  _c("span", { staticClass: "sold-out-label" }, [
                    _vm._v(_vm._s(_vm._f("translate")("webshopTicketSoldOut")))
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _vm.loading ? _c("loader", { attrs: { size: "small" } }) : _vm._e(),
      _vm._v(" "),
      _vm.selectedEventTime &&
      _vm.selectedEventTime.availableCapacity &&
      _vm.selectedEventTime.availableCapacity > 0
        ? [
            _c("span", { staticClass: "capacity-message" }, [
              _vm._v("\n      " + _vm._s(_vm.capacityMessage) + "\n    ")
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.tickets
        ? [
            _vm.selectedDate && !_vm.loading
              ? _c(
                  "ul",
                  {
                    class: { "ticket-types": true, inactive: !_vm.selectedDate }
                  },
                  _vm._l(_vm.formatTickets, function(item) {
                    return _c("ticket-list-item", {
                      key: item.id,
                      attrs: {
                        item: item,
                        "selected-event-time": _vm.selectedEventTime,
                        "current-capacity": _vm.currentCapacity,
                        type: _vm.type,
                        "disable-add": _vm.disableAdd,
                        "initial-capacity": _vm.initialCapacity
                      },
                      on: { update: _vm.onUpdateListItem }
                    })
                  }),
                  1
                )
              : _vm._e()
          ]
        : void 0
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }