var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSrc()
    ? _c("picture", [
        !_vm.isSvg()
          ? _c("source", {
              attrs: { type: "image/webp", srcset: _vm.srcSetUrl }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          class: [_vm.cssClass, "lazyload"],
          attrs: {
            src: _vm.src,
            alt: _vm.alt,
            title: _vm.title,
            height: _vm.height,
            width: _vm.width,
            loading: _vm.loadingType
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }