var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.relatedEvent
    ? _c(
        "div",
        { staticClass: "related-event" },
        [
          _c("render-image", {
            staticClass: "related-event__image",
            attrs: {
              src: "" + _vm.relatedEvent.imageUrl,
              alt: _vm.relatedEvent.title
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "related-event__content" }, [
            _vm.relatedEvent.title
              ? _c("h5", { staticClass: "related-event__title" }, [
                  _c("a", { attrs: { href: _vm.relatedEvent.url } }, [
                    _vm._v(_vm._s(_vm.relatedEvent.title))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.relatedEvent.description
              ? _c(
                  "div",
                  {
                    staticClass: "related-event__description",
                    domProps: {
                      innerHTML: _vm._s(_vm.relatedEvent.description)
                    }
                  },
                  [_vm._v(_vm._s(_vm.relatedEvent.description))]
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }