var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading && !_vm.hasTickets
        ? _c("loader", { attrs: { size: "large" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTickets
        ? _c(
            "card",
            { staticClass: "receipt-tickets" },
            [
              _c("h4", [_vm._t("ticket-header")], 2),
              _vm._v(" "),
              !_vm.maxRequestError
                ? [
                    !_vm.ticketReady
                      ? _c(
                          "div",
                          { staticClass: "receipt-download-message" },
                          [
                            _vm.loading
                              ? _c("loader", {
                                  staticClass: "ticket-loader",
                                  attrs: { size: "large" }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("ticket-pending-message")
                          ],
                          2
                        )
                      : _c(
                          "div",
                          { staticClass: "receipt-download-message" },
                          [
                            _vm._t("ticket-ready-message"),
                            _vm._v(" "),
                            _c(
                              "p",
                              [
                                _c("ticket-download", {
                                  attrs: {
                                    "order-id": _vm.orderId,
                                    "order-guid": _vm.guid
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          2
                        )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.maxRequestError
                ? _c("div", { staticClass: "receipt-download-message" }, [
                    _c(
                      "p",
                      [
                        _c("message", { attrs: { state: "danger" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("translate")(
                                  "WebshopErrorCodeGenericError"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }