import { Service } from "@jcd-web/basic-service";
import { OrderApi } from "../api/OrderApi";
export default class OrderService extends Service {
    static getOrder(id, guid) {
        let path = id;
        if (guid) {
            path += '?guid=' + guid;
        }
        return new Promise((resolve, reject) => {
            OrderApi.getOrder(path).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static downloadTickets(orderId, orderGuid) {
        return new Promise((resolve, reject) => {
            OrderApi.downloadTickets(orderId, orderGuid).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
}
