import { Service } from "@jcd-web/basic-service";
import { CountryApi } from "../api/CountryApi";
export class CountryService extends Service {
    static getAllActive() {
        return new Promise((resolve, reject) => {
            CountryApi.getAllActive().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // Helper functions
    static getDefaultCountry(countries) {
        if (countries) {
            let defaultCountry = countries.find(x => x.default);
            return defaultCountry;
        }
        return null;
    }
}
