var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cart
    ? _c("div", { staticClass: "checkout-cart", attrs: { grid: "wrap" } }, [
        _c(
          "div",
          { attrs: { column: "s-12 m-8" } },
          [
            _c("card", [
              _c("div", { attrs: { grid: "" } }, [
                _c("div", { attrs: { column: "m-6" } }, [
                  _c("header", { staticClass: "card-header" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("WebshopCheckoutPaymentBilling")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-text-small" },
                    [
                      _c("checkout-payment-address", {
                        attrs: { model: _vm.cart.billingAddress }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.cart.shippingRequired && _vm.cart.shipping
                  ? _c("div", { attrs: { column: "m-6" } }, [
                      _c("header", { staticClass: "card-header" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "WebshopCheckoutPaymentDelivery"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "shipping-method card-text-small" },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "WebshopCheckoutPaymentShippingMethod"
                                )
                              ) + ":"
                            )
                          ]),
                          _c("br"),
                          _vm._v(
                            "\n            " + _vm._s(_vm.shippingMethod.name)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "caption small" }, [
                            _vm._v(_vm._s(_vm.shippingMethod.description))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.shippingAddress
                        ? _c(
                            "div",
                            { staticClass: "card-text-small" },
                            [
                              _c("hr"),
                              _vm._v(" "),
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "WebshopCheckoutPaymentShippingAddress"
                                    )
                                  ) + ":"
                                )
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _c("checkout-payment-address", {
                                attrs: { model: _vm.shippingAddress }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "card",
              { staticClass: "order-summary hide-medium-up" },
              [
                _c("template", { slot: "header" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("WebshopCheckoutCartSummary"))
                  )
                ]),
                _vm._v(" "),
                _vm.cart
                  ? _c("checkout-order-summary", {
                      attrs: { "show-products": true, cart: _vm.cart }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("redeem-promotion-code", {
                  attrs: {
                    "current-promotion-code": _vm.cart.promotionCode,
                    "allow-promotion-code": _vm.promotionCodesEnabled
                  }
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "redeem-gift-card fit-height",
                attrs: { grid: "wrap" }
              },
              [
                _c(
                  "div",
                  { attrs: { column: "12" } },
                  [
                    _vm.giftCardEnabled
                      ? _c(
                          "card",
                          [
                            _c(
                              "header",
                              { staticClass: "card-inline-header icon-left" },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-wallet-giftcard icon"
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "WebshopCheckoutGiftCardHeader"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "card-text-small" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "WebshopCheckoutGiftCardText"
                                    )
                                  ) +
                                  "\n            "
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _c("br")
                            ]),
                            _vm._v(" "),
                            _c("redeem-gift-card", {
                              attrs: { "gift-card": _vm.cart.giftCard },
                              on: { update: _vm.onGiftCardUpdate }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "card",
              [
                !_vm.loading && !_vm.payload && !_vm.callPayment
                  ? [
                      _c("div", { staticClass: "terms-acceptance form" }, [
                        _c("form", [
                          _c("label", { staticClass: "checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.termsAccepted,
                                  expression: "termsAccepted"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.termsAccepted)
                                  ? _vm._i(_vm.termsAccepted, null) > -1
                                  : _vm.termsAccepted
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.termsAccepted,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.termsAccepted = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.termsAccepted = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.termsAccepted = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "check" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "description" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshopCheckoutPaymentIAccept"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.acceptTermsDescription)
                                }
                              })
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.enableNewsletterSubscription
                        ? _c("div", { staticClass: "form" }, [
                            _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newslettersAccepted,
                                    expression: "newslettersAccepted"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.newslettersAccepted
                                  )
                                    ? _vm._i(_vm.newslettersAccepted, null) > -1
                                    : _vm.newslettersAccepted
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.newslettersAccepted,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.newslettersAccepted = $$a.concat(
                                            [$$v]
                                          ))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.newslettersAccepted = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.newslettersAccepted = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "check" }),
                              _vm._v(" "),
                              _c("span", { staticClass: "description" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshopCheckoutAcceptNewsletters"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.termsAccepted
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "go-to-payment contained medium full",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.acceptTermsMessage()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("translate")(_vm.payLabel)) +
                                  "\n        "
                              )
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "go-to-payment contained medium full",
                              on: { click: _vm.goToPayment }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("translate")(_vm.payLabel)) +
                                  "\n        "
                              )
                            ]
                          )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.payload
                  ? [
                      _c(
                        "form",
                        {
                          ref: "paymentForm",
                          staticClass: "epay",
                          attrs: { action: _vm.payload.url, method: "post" }
                        },
                        [
                          _vm._l(_vm.payload.parameters, function(
                            value,
                            propertyName,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              _c("input", {
                                attrs: { name: propertyName, type: "hidden" },
                                domProps: { value: value }
                              })
                            ])
                          }),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "WebshopCheckoutPaymentAutoDoenstWork"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "contained full",
                              attrs: { type: "submit" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm._f("translate")(_vm.payLabel)) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loading && !_vm.payload && _vm.callPayment
                  ? [
                      _c("message", { attrs: { state: "danger" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errorMessage) +
                            "\n        "
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.loading ? [_c("loader")] : _vm._e()
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { column: "s-12 m-4" } },
          [
            _c(
              "card",
              { staticClass: "order-summary hide-small" },
              [
                _c("template", { slot: "header" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("WebshopCheckoutCartSummary"))
                  )
                ]),
                _vm._v(" "),
                _vm.cart
                  ? _c("checkout-order-summary", {
                      attrs: { "show-products": true, cart: _vm.cart }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("redeem-promotion-code", {
                  attrs: {
                    "current-promotion-code": _vm.cart.promotionCode,
                    "allow-promotion-code": _vm.promotionCodesEnabled
                  }
                })
              ],
              2
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }