var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let RenderImage = class RenderImage extends Vue {
    hasSrc() {
        return this.src !== undefined && this.src !== "";
    }
    isSvg() {
        return this.src.toLowerCase().includes(".svg");
    }
    get srcSetUrl() {
        return `${this.src}${this.src.includes("?") ? "&" : "?"}format=webp`;
    }
    get loadingType() {
        return this.disableLazyLoading ? "eager" : "lazy";
    }
};
RenderImage = __decorate([
    Component({
        props: {
            src: String,
            alt: String,
            title: String,
            cssClass: String,
            height: String,
            width: String,
            disableLazyLoading: {
                type: Boolean,
                default: false
            }
        }
    })
], RenderImage);
export default RenderImage;
