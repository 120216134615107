var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import "webshop/filters/translate";
import { CustomerType } from "webshop/models/admission-card/AdmissionCard";
class TableRow {
    constructor(dictionary, value) {
        this.dictionary = dictionary;
        this.value = value;
    }
}
let CheckoutPaymentAddress = class CheckoutPaymentAddress extends Vue {
    get address() {
        let rows = [];
        let model = this.model;
        function addRow(dictionary, value) {
            if (value) {
                rows.push(new TableRow(dictionary, value));
            }
        }
        if (model.customerType === CustomerType.Business) {
            addRow("WebshopCheckoutAddressCompanyName", model.name);
            addRow("WebshopCheckoutAddressName", model.contact);
        }
        else if (model.contact) {
            addRow("WebshopCheckoutAddressCompanyName", model.name);
            addRow("WebshopCheckoutAddressName", model.contact);
        }
        else {
            addRow("WebshopCheckoutAddressName", model.name);
        }
        addRow("WebshopCheckoutAddressStreet", model.address);
        addRow("WebshopCheckoutAddressStreet2", model.address2);
        if (model.zipCode && model.city) {
            addRow("WebshopCheckoutAddressZipCity", `${model.zipCode} ${model.city}`);
        }
        else if (model.city) {
            addRow("WebshopCheckoutAddressCity", model.city);
        }
        else if (model.zipCode) {
            addRow("WebshopCheckoutAddressZip", model.zipCode);
        }
        addRow("WebshopCheckoutAddressCountry", model.countryCode);
        addRow("WebshopCheckoutAddressCustomerType", model.customerType ? Vue.filter('translate')("WebshopCheckoutAddressCustomerType" + model.customerType) : null);
        addRow("WebshopCheckoutAddressPhone", model.phoneNumber);
        addRow("WebshopCheckoutAddressEmail", model.email);
        return rows;
    }
};
CheckoutPaymentAddress = __decorate([
    Component({
        props: {
            model: Object
        }
    })
], CheckoutPaymentAddress);
export default CheckoutPaymentAddress;
