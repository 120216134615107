var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-component",
      class: [{ clickable: _vm.options.clickable }, _vm.mods]
    },
    [
      _vm.$slots.image
        ? _c("picture", { staticClass: "card-image" }, [_vm._t("image")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.header
        ? _c("header", { staticClass: "card-header" }, [_vm._t("header")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.description
        ? _c(
            "div",
            { staticClass: "card-description" },
            [_vm._t("description")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c("header", { staticClass: "card-footer" }, [_vm._t("footer")], 2)
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }