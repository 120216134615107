var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let Modal = class Modal extends Vue {
    constructor() {
        super(...arguments);
        this.modalWidth = this.customWidth ? (this.customWidth + 'px') : "640px";
        this.modalHeight = this.customHeight ? (this.customHeight + 'px') : "";
        this.modalMaxHeight = this.customMaxHeight ? (this.customMaxHeight + 'px') : "";
    }
    beforeMount() {
    }
    mounted() {
    }
    close() {
        this.$emit('close');
    }
};
Modal = __decorate([
    Component({
        props: {
            show: Boolean,
            customWidth: Number,
            customHeight: Number,
            customMaxHeight: Number,
            customClass: {
                type: String,
                default: ""
            }
        }
    })
], Modal);
export default Modal;
