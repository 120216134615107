var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout-login" },
    [
      _c("message", [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm._f("translate")("WebshopCheckoutInformationLoginMessage")
            ) +
            "\n    "
        ),
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.toggleLogin.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm._f("translate")("webshopLoginButton")))]
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { "custom-class": "theme-white", show: _vm.showModal },
          on: { close: _vm.onClose }
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _c("login-form", { attrs: { "show-forgot-password": true } }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm._f("translate")("WebshopLoginModalHeader")))
                ]),
                _vm._v(" "),
                _vm.loginDescription
                  ? _c("p", [
                      _vm._v(_vm._s(_vm.loginDescription)),
                      _c("br"),
                      _c("br")
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }