var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { BillingAddressInfo } from "../../models/checkout/Checkout";
import CartService from "../../services/CartService";
import CheckoutOrderSummary from "../../components/checkout/CheckoutOrderSummary.vue";
import WebshopEvents, { WebshopEventTypes } from "../../utilities/WebshopEvents";
import { EventTypes } from "../../models/EventTypes";
import { CustomerType } from "../../models/admission-card/AdmissionCard";
import CheckoutDisplayUserData from "../../components/checkout/CheckoutDisplayUserData.vue";
import CheckoutLogin from '../../components/checkout/CheckoutLogin.vue';
import { WebshopConfig } from "../../config";
import { CountryService } from "../../services/CountryService";
import { ToastService } from "@jcd-web/basic-toast";
import { ErrorService } from "webshop/services/ErrorService";
import RedeemPromotionCode from "../../components/promotion-codes/RedeemPromotionCode.vue";
let CheckoutInformation = class CheckoutInformation extends Vue {
    constructor() {
        super(...arguments);
        this.customerType = CustomerType.Consumer;
        this.config = WebshopConfig;
        this.billingAddressRequired = WebshopConfig.ecommerce.billingAddressRequired;
        this.cart = null;
        this.loading = true;
        this.countries = [];
        this.validateEmailMessage = false;
        this.validateEmailErrorMessage = "";
        this.invalidAddressMessage = false;
        this.invalidAddressErrorMessage = "";
    }
    get nextStepLabel() {
        return this.cart?.shippingRequired ? "WebshopCheckoutStepsToShipping" : "WebshopCheckoutStepsToPayment";
    }
    get promotionCodesEnabled() {
        return false;
    }
    // get myProfilePageURL() {
    //   return this.config.paths.userProfile + "?returnURL=" + this.currentPath;
    // }
    init() {
        const contries = CountryService.getAllActive();
        const cart = CartService.getCart();
        Promise.all([contries, cart]).then(res => {
            if (res) {
                let countriesRes = res[0];
                let cartRes = res[1];
                this.initCountries(countriesRes);
                this.initCart(res[1]);
                this.loading = false;
            }
        });
    }
    initCountries(countries) {
        this.countries = countries;
    }
    initCart(cart) {
        // Hvis cart returnerer en 204 No content er kurven tom
        if (typeof (cart) === 'string') {
            this.$router.push("cart");
            return;
        }
        this.cart = cart;
        let _billingAddress = this.cart?.billingAddress;
        if (!_billingAddress) {
            this.cart.billingAddress = new BillingAddressInfo();
            // Sæt default land
            let defaultCountry = CountryService.getDefaultCountry(this.countries);
            this.cart.billingAddress.countryCode = defaultCountry ? defaultCountry.code : "";
            // Sæt default customerType til consumer
            this.customerType = CustomerType.Consumer;
        }
        else {
            if (_billingAddress.customerType === CustomerType.Business) {
                _billingAddress._companyName = _billingAddress.name;
                _billingAddress.name = _billingAddress.contact;
            }
            // Sæt validate email felt til email-værdi hvis den er udfyldt (selvom den altid burde være det)
            _billingAddress.emailConfirmation = _billingAddress.email || "";
            // Sæt customerType hvis den findes
            this.customerType = this.cart.billingAddress.customerType;
        }
        WebshopEvents.$emit(WebshopEventTypes.CheckoutUpdateCart, cart);
    }
    // giftCardData: GiftCard;
    updateView() {
        this.$forceUpdate();
    }
    updateCustomerType() {
        if (this.cart && this.cart.billingAddress) {
            this.cart.billingAddress.customerType = this.customerType;
        }
    }
    mounted() {
        this.init();
        // this.currentPath = window.location.pathname;
        WebshopEvents.$emit(EventTypes.UpdateStep, 2);
        WebshopEvents.$on(WebshopEventTypes.CheckoutPromotionCodeUpdate, this.init);
    }
    toggleEmailConflict(error) {
        this.validateEmailMessage = true;
        this.validateEmailErrorMessage = error;
        ToastService.danger(error, 4000);
    }
    toggleInvalidAddress(error) {
        this.invalidAddressMessage = true;
        this.invalidAddressErrorMessage = error;
        ToastService.danger(error, 4000);
    }
    ;
    submit() {
        this.validateEmailMessage = false;
        this.invalidAddressMessage = false;
        let data = Object.assign({}, this.cart.billingAddress);
        if (this.currentUser) {
            data = null;
        }
        else {
            // Opdater customertype på data objekt
            data.customerType = this.customerType;
            // Hvis kundetypen er privat, skal companyname sættes til null
            if (data.customerType == CustomerType.Consumer) {
                data._companyName = null;
            }
            // Ellers skal name => contact og _companyName => name
            else {
                data.contact = data.name;
                data.name = data._companyName;
            }
            // Afbryd hvis emails ikke er ens
            if (data.email !== data.emailConfirmation) {
                let _message = Vue.filter("translate")("WebshopCheckoutInformationEmailValidateMessage");
                this.toggleEmailConflict(_message);
                return;
            }
        }
        // Validate address
        CartService.updateBillingAddress(data).then(res => {
            this.cart.billingAddress = data;
            // StorageService.setItem("currentAddress", JSON.stringify(this.model));
            // WebshopEvents.$emit(EventTypes.UpdateInformation, this.model);
            // Gå til leveringsvalg hvis det er påkrævet i kurven
            if (this.cart.shippingRequired) {
                this.$router.push("delivery");
            }
            // Ellers gå direkte til payment
            else {
                this.$router.push("payment");
            }
        }, err => {
            let errorCode = err.response?.data?.code;
            switch (errorCode) {
                case "UserEmailExists":
                    ErrorService.handleError(err.response, true).then(res => {
                        this.toggleEmailConflict(res);
                    });
                    break;
                case "CheckoutInvalidAddress":
                    ErrorService.handleError(err.response, true).then(res => {
                        this.toggleInvalidAddress(res);
                    });
                    break;
                default:
                    ErrorService.handleError(err);
            }
        });
    }
};
CheckoutInformation = __decorate([
    Component({
        props: {
            currentUser: Object,
        },
        components: {
            CheckoutOrderSummary,
            CheckoutDisplayUserData,
            CheckoutLogin,
            RedeemPromotionCode
        },
    })
], CheckoutInformation);
export default CheckoutInformation;
