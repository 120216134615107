var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    _c("label", { staticClass: "checkbox" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.accept,
            expression: "accept"
          }
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.accept)
            ? _vm._i(_vm.accept, null) > -1
            : _vm.accept
        },
        on: {
          change: function($event) {
            var $$a = _vm.accept,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.accept = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.accept = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.accept = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "box" }),
      _vm._v(" "),
      _c("span", { staticClass: "description" }, [
        _vm._v("\n        Modtag nyhedsbreve\n        ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }