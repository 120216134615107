var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { StorageService } from "@jcd-web/basic-storage";
import OrderService from '../services/OrderService';
import { TicketStatus } from '../models/orders/Order';
import TicketDownload from '../components/tickets/TicketDownload.vue';
import { ToastService } from "@jcd-web/basic-toast";
import { TrackingService } from "../services/TrackingService";
let Receipt = class Receipt extends Vue {
    constructor() {
        super(...arguments);
        this.order = null;
        this.ticketStatus = TicketStatus.pending;
        this.loading = true;
        this.hasTickets = false;
        this.requestCount = 0;
        this.requestTimer = 12000; //ms
        this.maxRequestCount = 10;
        this.maxRequestError = false;
        this.tracked = false;
    }
    mounted() {
        StorageService.removeItem("currentAddress");
        StorageService.removeItem("currentAdmissionCard");
        this.requestTicketStatus(true);
    }
    get ticketReady() {
        return this.ticketStatus == TicketStatus.ready;
    }
    track(order) {
        this.tracked = true;
        TrackingService.purchase(order);
    }
    requestTicketStatus(skipTimeout) {
        if (skipTimeout) {
            this.getTicketStatus();
        }
        else {
            if (this.requestCount < this.maxRequestCount) {
                setTimeout(() => {
                    this.getTicketStatus();
                }, this.requestTimer);
                this.requestCount++;
            }
            else {
                this.maxRequestError = true;
            }
        }
    }
    getTicketStatus() {
        OrderService.getOrder(this.orderId, this.guid).then(res => {
            let order = res;
            if (!this.tracked) {
                this.track(res);
            }
            if (order.ticketStatus) {
                this.ticketStatus = order.ticketStatus;
                this.hasTickets = true;
                if (order.ticketStatus == TicketStatus.ready) {
                    ToastService.success(Vue.filter("translate")("webshopReceiptTicketDownloadReady"), 3000);
                    this.loading = false;
                }
                else if (order.ticketStatus == TicketStatus.pending) {
                    this.requestTicketStatus();
                }
                // else - håndtering af fejl og andet?
            }
            else {
                this.loading = false;
            }
        }, err => {
            this.loading = false;
            // Handle error
        });
    }
};
Receipt = __decorate([
    Component({
        props: {
            orderId: String,
            guid: String
        },
        components: {
            TicketDownload
        }
    })
], Receipt);
export default Receipt;
