var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Loader from "components/utility/Loader.vue";
import OrderService from '../../services/OrderService';
import "webshop/filters/translate";
import { ErrorService } from "webshop/services/ErrorService";
let TicketDownload = class TicketDownload extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.downloading = false;
    }
    download() {
        this.downloading = true;
        OrderService.downloadTickets(this.orderId, this.orderGuid).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, Vue.filter("translate")('WebshopPdfFilename') + '.pdf');
            }
            else {
                const exportUrl = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = exportUrl;
                a.download = Vue.filter("translate")('WebshopPdfFilename') + '.pdf';
                a.click();
            }
            this.downloading = false;
        })
            .catch(err => {
            this.downloading = false;
            ErrorService.handleError(err.response);
        });
    }
    mounted() {
        this.loading = false;
    }
};
TicketDownload = __decorate([
    Component({
        components: {
            Loader
        },
        props: {
            orderId: String,
            orderGuid: String
        }
    })
], TicketDownload);
export default TicketDownload;
