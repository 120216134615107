var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cart
    ? _c("div", { staticClass: "checkout-cart" }, [
        _c("div", { attrs: { grid: "wrap" } }, [
          _c(
            "div",
            { attrs: { column: "s-12 l-8" } },
            [
              _c(
                "card",
                [
                  _c("checkout-shipping-methods", {
                    attrs: {
                      "selected-shipping-method": _vm.selectedShippingMethod,
                      cart: _vm.cart
                    },
                    on: {
                      select: _vm.onSelectShippingMethod,
                      "go-to-payment": _vm.onGoToPayment
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { column: "s-12 l-4" } },
            [
              _c(
                "card",
                { staticClass: "order-summary" },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(
                      _vm._s(_vm._f("translate")("WebshopCheckoutCartSummary"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("checkout-order-summary", {
                    attrs: { "show-products": true, cart: _vm.cart }
                  }),
                  _vm._v(" "),
                  _c("redeem-promotion-code", {
                    attrs: {
                      "current-promotion-code": _vm.cart.promotionCode,
                      "allow-promotion-code": _vm.promotionCodesEnabled
                    }
                  })
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }