import { Service } from "@jcd-web/basic-service";
import WebshopEvents from "webshop/utilities/WebshopEvents";
export class ErrorService extends Service {
    static handleError(response, hideToastMessage) {
        return new Promise((resolve, reject) => {
            let dictionaryItem = "webshopErrorCode";
            let genericErrorCode = "webshopErrorCodeGenericError";
            let errorMessage = "";
            if (response && response.data && response.data.code) {
                dictionaryItem += response.data.code;
            }
            else {
                dictionaryItem = genericErrorCode;
            }
            //Fetch dictionary
            let _window = window;
            let dictionary = _window && _window.dictionary ? _window.dictionary : null;
            if (dictionary && dictionary[dictionaryItem] !== undefined) {
                if (dictionary[dictionaryItem].length < 1) {
                    errorMessage = dictionary[genericErrorCode];
                }
                else {
                    errorMessage = dictionary[dictionaryItem];
                }
            }
            else if (dictionary && dictionary[dictionaryItem] === undefined && dictionary[genericErrorCode] !== undefined) {
                errorMessage = dictionary[genericErrorCode];
            }
            else {
                // Burde man her kunne smide et backend kald, at der mangler et dictionary item?
                // reject(dictionaryItem);
            }
            resolve(errorMessage);
            if (!hideToastMessage) {
                WebshopEvents.$emit('handleError', errorMessage);
            }
        });
    }
    static resolveErrorMessage(value) {
        return new Promise((resolve, reject) => {
            const genericErrorCode = "webshopErrorCodeGenericError";
            let dictionaryItem = value;
            let errorMessage = "";
            dictionaryItem = dictionaryItem ? dictionaryItem[0].toLowerCase() + dictionaryItem.substring(1) : "";
            //Fetch dictionary
            const _window = window;
            const dictionary = _window && _window.dictionary ? _window.dictionary : null;
            if (dictionary && dictionary[dictionaryItem] !== undefined) {
                if (dictionary[dictionaryItem].length < 1) {
                    errorMessage = dictionary[genericErrorCode];
                }
                else {
                    errorMessage = dictionary[dictionaryItem];
                }
            }
            else if (dictionary && dictionary[dictionaryItem] === undefined && dictionary[genericErrorCode] !== undefined) {
                errorMessage = dictionary[genericErrorCode];
            }
            resolve(errorMessage);
        });
    }
}
