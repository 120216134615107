var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CartService from "../../services/CartService";
import WebshopEvents, { WebshopEventTypes } from "../../utilities/WebshopEvents";
import { EventTypes } from "../../models/EventTypes";
import { ToastService } from "@jcd-web/basic-toast";
import RedeemPromotionCode from "../../components/promotion-codes/RedeemPromotionCode.vue";
// Components
import CartLine from "../../components/cart/CartLine.vue";
import CheckoutOrderSummary from "../../components/checkout/CheckoutOrderSummary.vue";
import { ErrorService } from "webshop/services/ErrorService";
import { WebshopConfig } from "webshop/config";
let CheckoutCart = class CheckoutCart extends Vue {
    constructor() {
        super(...arguments);
        this.cart = null;
        this.loading = true;
        this.emptyCart = false;
    }
    get promotionCodesEnabled() {
        return WebshopConfig.ecommerce.promotionCodesEnabled;
    }
    onDeleteLine() {
        this.getCart();
    }
    onUpdateLine() {
        this.getCart();
    }
    resetCart() {
        CartService.deleteCart().then((res) => {
            this.getCart();
        }, (err) => {
            ToastService.danger(err, 4000);
        });
    }
    getCart() {
        // Update mini cart
        WebshopEvents.$emit("update-cart-mini");
        // Reload cart
        CartService.getCart().then((cart) => {
            if (cart.lines != null && cart.lines.length > 0) {
                this.cart = cart;
                WebshopEvents.$emit(WebshopEventTypes.CheckoutUpdateCart, cart);
            }
            else {
                this.emptyCart = true;
                WebshopEvents.$emit(WebshopEventTypes.CheckoutShowProgress, !this.emptyCart);
            }
            this.loading = false;
        }, (err) => {
            this.loading = false;
            ErrorService.handleError(err);
        });
    }
    mounted() {
        this.getCart();
        // Update checkout progress
        WebshopEvents.$emit(EventTypes.UpdateStep, 1);
        // Update checkout promotion code
        WebshopEvents.$on(WebshopEventTypes.CheckoutPromotionCodeUpdate, this.getCart);
    }
};
CheckoutCart = __decorate([
    Component({
        components: {
            CartLine,
            CheckoutOrderSummary,
            RedeemPromotionCode
        },
        props: {
            checkout: Object
        }
    })
], CheckoutCart);
export default CheckoutCart;
