var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let Message = class Message extends Vue {
    get sizeClass() {
        return this.size;
    }
    get stateClass() {
        return `${this.state}`;
    }
    get hasIcon() {
        return !!this.icon;
    }
    get iconClass() {
        return this.icon ? `mdi mdi-${this.icon}` : "";
    }
};
Message = __decorate([
    Component({
        props: {
            state: String,
            icon: String,
            size: String
        }
    })
], Message);
export default Message;
export var MessageSize;
(function (MessageSize) {
    MessageSize["Small"] = "small";
    MessageSize["Normal"] = "normal";
    MessageSize["Large"] = "large";
})(MessageSize || (MessageSize = {}));
export var MessageState;
(function (MessageState) {
    MessageState["Success"] = "success";
    MessageState["Danger"] = "danger";
    MessageState["Neutral"] = "neutral";
})(MessageState || (MessageState = {}));
export class StatusMessage {
    constructor(state, text, icon) {
        this.state = state;
        this.text = text;
        this.icon = icon;
    }
}
