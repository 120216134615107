var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "cart-mini site-header__shortcut-item",
      attrs: {
        href: _vm.cartPage,
        title: _vm._f("translate")("webshopMiniCartGoToCart")
      }
    },
    [
      _c(
        "svg",
        {
          staticClass: "site-header__shortcut-item-icon cart-mini__icon",
          attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41A1,1,0,0,1,21.4,6.178Z"
            }
          }),
          _c("circle", { attrs: { cx: "7", cy: "22", r: "2" } }),
          _c("circle", { attrs: { cx: "17", cy: "22", r: "2" } })
        ]
      ),
      _vm._v(" "),
      _vm.cart && _vm.cart.quantity > 0
        ? [
            _c("span", { staticClass: "cart-mini__quantity-value" }, [
              _vm._v(_vm._s(_vm.cart.quantity))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "cart-mini__quantity-background" })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.labelActive ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }