var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
// Services
import WebshopEvents from "webshop/utilities/WebshopEvents";
let TicketDrawerButton = class TicketDrawerButton extends Vue {
    toggle() {
        let ticketPickerData = {
            tickets: this.tickets,
            pickDateLabel: this.pickDateLabel,
            pickTicketsLabel: this.pickTicketsLabel,
            autoSelectDate: this.autoSelectDate
        };
        WebshopEvents.$emit("toggle-drawer", ticketPickerData);
    }
};
TicketDrawerButton = __decorate([
    Component({
        props: {
            tickets: Array,
            pickDateLabel: String,
            pickTicketsLabel: String,
            autoSelectDate: {
                type: Boolean,
                default: false
            }
        },
    })
], TicketDrawerButton);
export default TicketDrawerButton;
