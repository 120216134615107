var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "drawer-menu" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDrawer,
            expression: "showDrawer"
          }
        ],
        staticClass: "drawer-menu",
        style: { "max-width": _vm.maxWidth, "min-width": _vm.minWidth }
      },
      [_vm._t("body")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }