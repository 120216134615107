var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { EventService } from "services/EventService";
let EventList = class EventList extends Vue {
    constructor() {
        super(...arguments);
        this.eventList = [];
        this.selectedFilter = "";
        this.sortOrder = "dateAsc";
    }
    mounted() {
        EventService.getlist().then(r => {
            this.eventList = r;
        });
    }
    filteredEventList() {
        const list = this.selectedFilter != ""
            ? this.eventList.filter(e => e.location == this.selectedFilter)
            : [...this.eventList];
        switch (this.sortOrder) {
            case "dateAsc":
                return list.sort((a, b) => a.sortDate > b.sortDate ? 1 : b.sortDate > a.sortDate ? -1 : 0);
            case "dateDesc":
                return list.sort((a, b) => a.sortDate < b.sortDate ? 1 : b.sortDate < a.sortDate ? -1 : 0);
            // case "nameAsc":
            //   return list.sort((a, b) =>
            //     a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            //   );
            // case "nameDesc":
            //   return list.sort((a, b) =>
            //     a.name < b.name ? 1 : b.name < a.name ? -1 : 0
            //   );
        }
    }
    getFiltersFromList() {
        return this.eventList.map(e => e.location.length > 0 ? e.location : null).filter((v, i, a) => a.indexOf(v) === i).sort();
    }
};
EventList = __decorate([
    Component({})
], EventList);
export default EventList;
