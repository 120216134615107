var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { TicketType } from "../../models/ticket/Ticket";
import EventTime from './EventTime.vue';
let TicketListItem = class TicketListItem extends Vue {
    constructor() {
        super(...arguments);
        this.ticket = null;
        this.quantity = 0;
        this.currentQuantity = this.quantity;
    }
    mounted() {
        this.ticket = this.item.ticket;
        this.quantity = this.item.quantity;
    }
    get enableCapacity() {
        if (this.selectedEventTime && this.selectedEventTime.availableCapacity != null) {
            return true;
        }
        return false;
    }
    get disableRemove() {
        return this.quantity <= 0 ? true : false;
    }
    get showDate() {
        return this.type == TicketType.Event;
    }
    get isSoldOut() {
        if (this.currentCapacity == null || this.selectedEventTime == null) {
            return false;
        }
        else {
            return this.selectedEventTime.availableCapacity == 0 ? true : false;
        }
    }
    select(e) {
        if (e && e.target) {
            e.target.select();
        }
    }
    update() {
        // Hvis indtastet værdi er mindre end nul - så sætter den værdi til tidligere værdi = currentQuantity = quantity = 0
        if (this.quantity < 0) {
            this.quantity = this.currentQuantity;
        }
        // Hvis der ikke er nok varer i capacity (og det er en vare med kapacitet),
        // bliver den ønskede quantity ændret til currentQuantity
        else if (this.currentCapacity < 0 && this.enableCapacity) {
            this.quantity = this.currentQuantity;
        }
        // ellers bliver currentQuantity opdateret med den nye quantity
        else {
            this.$emit('update', this.item, this.quantity);
            this.currentQuantity = this.quantity;
        }
    }
    onChange() {
        // Hvis quantity opjusteres med mere end der er tilbage i currentCapacity,
        // skal antallet ændres tilbage til den oprindelige quantity(currentQuantity)
        if (this.enableCapacity) {
            if (this.currentCapacity === null) {
                if (this.quantity > this.initialCapacity) {
                    this.quantity = this.initialCapacity;
                }
            }
            else {
                let maxCapacity = this.currentQuantity + this.currentCapacity;
                if (this.quantity > maxCapacity) {
                    this.quantity = this.currentQuantity;
                }
            }
        }
        this.update();
    }
    removeTicket() {
        if (this.quantity >= 1) {
            this.quantity--;
            this.update();
        }
    }
    addTicket() {
        if (this.enableCapacity && this.currentCapacity !== null) {
            if (this.currentCapacity > 0) {
                this.quantity++;
                this.update();
            }
        }
        else {
            this.quantity++;
            this.update();
        }
    }
};
TicketListItem = __decorate([
    Component({
        props: {
            type: String,
            item: Object,
            selectedEventTime: Object,
            currentStock: Number,
            currentCapacity: Number || null,
            disableAdd: Boolean,
            initialCapacity: Number || null
        },
        components: {
            EventTime
        }
    })
], TicketListItem);
export default TicketListItem;
