var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "checkout-shipping-methods" },
    [
      _vm.loading ? _c("loader", { attrs: { size: "large" } }) : _vm._e(),
      _vm._v(" "),
      !_vm.loading && _vm.hasShippingMethods
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.goToPayment.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "ul",
                { staticClass: "options" },
                _vm._l(_vm.shippingMethods, function(option, $index) {
                  return _c(
                    "li",
                    {
                      key: $index,
                      staticClass: "option",
                      class: { selected: _vm.isShippingMethodSelected(option) }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "option-content",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectShippingMethod(option)
                            }
                          }
                        },
                        [
                          _vm._m(0, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _c("header", [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(option.name) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "price" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(option.price) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              option.description
                                ? _c("div", { staticClass: "description" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(option.description) +
                                        "\n                "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      option.selected && option.hasPickupLocationProvider
                        ? _c(
                            "footer",
                            { staticClass: "option-footer" },
                            [
                              _c("pickup-locations", {
                                attrs: {
                                  "shipping-method": option,
                                  "billing-address": _vm.cart.billingAddress
                                },
                                on: { select: _vm.onPickupLocationSelect }
                              })
                            ],
                            1
                          )
                        : option.selected &&
                          option.allowAlternativeDeliveryAddress
                        ? _c("footer", { staticClass: "option-footer" }, [
                            _c(
                              "div",
                              { staticClass: "form" },
                              [
                                !_vm.requireAltDeliveryAddress(option)
                                  ? _c("label", { staticClass: "checkbox" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              option._useAltDeliveryAddress,
                                            expression:
                                              "option._useAltDeliveryAddress"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "useAltDeliveryAddress"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            option._useAltDeliveryAddress
                                          )
                                            ? _vm._i(
                                                option._useAltDeliveryAddress,
                                                null
                                              ) > -1
                                            : option._useAltDeliveryAddress
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a =
                                                  option._useAltDeliveryAddress,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      option,
                                                      "_useAltDeliveryAddress",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      option,
                                                      "_useAltDeliveryAddress",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  option,
                                                  "_useAltDeliveryAddress",
                                                  $$c
                                                )
                                              }
                                            },
                                            function($event) {
                                              return _vm.toggleAltDeliveryAddress(
                                                option
                                              )
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "check" }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "description" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("translate")(
                                                "WebshopCheckoutDeliveryUseAltAddress"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                option._useAltDeliveryAddress ||
                                _vm.requireAltDeliveryAddress(option)
                                  ? [
                                      _vm.requireCompanyName(option)
                                        ? _c(
                                            "label",
                                            { staticClass: "field" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      option.address
                                                        ._companyName,
                                                    expression:
                                                      "option.address._companyName"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  name: "companyName",
                                                  placeholder: " ",
                                                  maxlength: "100",
                                                  required: _vm.requireCompanyName(
                                                    option
                                                  )
                                                },
                                                domProps: {
                                                  value:
                                                    option.address._companyName
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      option.address,
                                                      "_companyName",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "placeholder" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("translate")(
                                                          "WebshopCheckoutDeliveryCompanyName"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _vm.requireCompanyName(option)
                                                    ? [_vm._v(" *")]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("label", { staticClass: "field" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: option.address.name,
                                              expression: "option.address.name"
                                            }
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: "name",
                                            placeholder: " ",
                                            maxlength: "100",
                                            required: ""
                                          },
                                          domProps: {
                                            value: option.address.name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                option.address,
                                                "name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "placeholder" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")(
                                                  "WebshopCheckoutDeliveryName"
                                                )
                                              ) + " *"
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("label", { staticClass: "field" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: option.address.address,
                                              expression:
                                                "option.address.address"
                                            }
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: "address",
                                            placeholder: " ",
                                            maxlength: "100",
                                            required: ""
                                          },
                                          domProps: {
                                            value: option.address.address
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                option.address,
                                                "address",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "placeholder" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")(
                                                  "WebshopCheckoutDeliveryAddress"
                                                )
                                              ) + " *"
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("label", { staticClass: "field" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: option.address.address2,
                                              expression:
                                                "option.address.address2"
                                            }
                                          ],
                                          attrs: {
                                            type: "text",
                                            name: "address2",
                                            placeholder: " ",
                                            maxlength: "50"
                                          },
                                          domProps: {
                                            value: option.address.address2
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                option.address,
                                                "address2",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "placeholder" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")(
                                                  "WebshopCheckoutDeliveryAddress2"
                                                )
                                              ) + " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { attrs: { grid: "" } }, [
                                        _c(
                                          "div",
                                          { attrs: { column: "m-4" } },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "field" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        option.address.zipCode,
                                                      expression:
                                                        "option.address.zipCode"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    name: "zipCode",
                                                    placeholder: " ",
                                                    maxlength: "20",
                                                    required: ""
                                                  },
                                                  domProps: {
                                                    value:
                                                      option.address.zipCode
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        option.address,
                                                        "zipCode",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "placeholder"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("translate")(
                                                          "WebshopCheckoutDeliveryZipCode"
                                                        )
                                                      ) + " *"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { attrs: { column: "m-8" } },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "field" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        option.address.city,
                                                      expression:
                                                        "option.address.city"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    name: "city",
                                                    placeholder: " ",
                                                    maxlength: "30",
                                                    required: ""
                                                  },
                                                  domProps: {
                                                    value: option.address.city
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        option.address,
                                                        "city",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "placeholder"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("translate")(
                                                          "WebshopCheckoutDeliveryCity"
                                                        )
                                                      ) + " *"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      option.country
                                        ? _c(
                                            "label",
                                            { staticClass: "field read-only" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "read-only-value"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        option.country.name
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { attrs: { placeholder: "" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("translate")(
                                                        "WebshopCheckoutDeliveryCountryCode"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { attrs: { grid: "" } }, [
                _c("div", { attrs: { column: "6" } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_text-right", attrs: { column: "6" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button contained primary medium full icon-right",
                        class: { disabled: !_vm.hasSelectedShippingMethod },
                        attrs: {
                          type: "submit",
                          disabled: !_vm.hasSelectedShippingMethod
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")("WebshopCheckoutStepsToPayment")
                          ) + "\n        "
                        ),
                        _c("i", { staticClass: "mdi mdi-chevron-right icon" })
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        : !_vm.loading && !_vm.hasShippingMethods
        ? _c("div", [
            _c("div", { staticClass: "checkout-splash" }, [
              _c(
                "div",
                { staticClass: "image-wrapper" },
                [
                  _c("render-image", {
                    attrs: {
                      src: "/app/dist/assets/webshop/icons/fi-rr-box.svg",
                      alt: "No shipping method",
                      width: "150",
                      height: "150"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content-wrapper" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("translate")(
                        "WebshopCheckoutDeliveryNoShippingMethod"
                      )
                    ) +
                    "\n      "
                )
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "option-state" }, [
      _c("span", { staticClass: "state" }, [_c("span")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }