var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkout-cart" }, [
    _c(
      "div",
      { attrs: { grid: "wrap" } },
      [
        _vm.loading
          ? _c(
              "div",
              { attrs: { column: "12" } },
              [_c("loader", { attrs: { size: "large" } })],
              1
            )
          : !_vm.loading && _vm.emptyCart
          ? [
              _c("div", { attrs: { column: "s-12" } }, [
                _c("div", { staticClass: "cart-splash" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("p", { staticClass: "cart-splash-text" }, [
                    _vm._v(
                      _vm._s(_vm._f("translate")("WebshopCheckoutCartEmpty"))
                    )
                  ])
                ])
              ])
            ]
          : _vm.cart && _vm.cart.lines
          ? [
              _c(
                "div",
                { attrs: { column: "s-12 l-8" } },
                [
                  _c("card", { staticClass: "cart-sum-up" }, [
                    _c("h2", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm._f("translate")("webshopCheckoutCart")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cart-lines" },
                      _vm._l(_vm.cart.lines, function(item) {
                        return _c("cart-line", {
                          key: item.guid,
                          attrs: { item: item },
                          on: {
                            "delete-line": _vm.onDeleteLine,
                            "update-line": _vm.onUpdateLine
                          }
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "footer-buttons" }, [
                      _c("a", { staticClass: "button", attrs: { href: "/" } }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("translate")(
                                "WebshopCheckoutCartContinueShopping"
                              )
                            ) +
                            "\n                        "
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { column: "s-12 l-4" } },
                [
                  _c(
                    "card",
                    { staticClass: "order-summary" },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")("WebshopCheckoutCartSummary")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm.cart
                        ? _c("checkout-order-summary", {
                            attrs: { "show-products": false, cart: _vm.cart }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("redeem-promotion-code", {
                        attrs: {
                          "current-promotion-code": _vm.cart.promotionCode,
                          "allow-promotion-code": _vm.promotionCodesEnabled
                        }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.cart && _vm.cart.lines && _vm.cart.lines.length > 0
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "button contained medium primary full",
                              attrs: { to: "information", tag: "a" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "WebshopCheckoutStepsToInformation"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ]
          : [
              _c("div", { staticClass: "cart-splash" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("p", { staticClass: "cart-splash-text" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("WebshopCheckoutCartError"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.resetCart()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("translate")(
                            "WebshopCheckoutCartErrorResetCart"
                          )
                        ) +
                        "\n                "
                    )
                  ]
                )
              ])
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "cart-splash-icon" }, [
      _c("i", { staticClass: "mdi mdi-cart-outline" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "cart-splash-icon" }, [
      _c("i", { staticClass: "mdi mdi-alert-outline" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }