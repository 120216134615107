var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CartService from "webshop/services/CartService";
import { ErrorService } from "webshop/services/ErrorService";
import { UpdateShippingData, AddressType } from "../../../models/checkout/shipping/Shipping";
import PickupLocations from './PickupLocations.vue';
import WebshopEvents, { WebshopEventTypes } from '../../../utilities/WebshopEvents';
import { AddressInfo, DeliveryAddressInfo } from "webshop/models/checkout/Checkout";
let CheckoutShippingMethods = class CheckoutShippingMethods extends Vue {
    constructor() {
        super(...arguments);
        this.selectedPickupLocation = null;
        this.shippingMethods = [];
        this.loading = true;
    }
    get billingCountryCode() {
        return this.cart?.billingAddress?.countryCode || "";
    }
    get hasShippingMethods() {
        return this.shippingMethods?.length > 0;
    }
    get hasSelectedShippingMethod() {
        return this.selectedShippingMethod ? true : false;
    }
    created() {
        WebshopEvents.$on(WebshopEventTypes.ShippingMethodUpdate, this.updateShippingMethod);
    }
    updateShippingMethod() {
        const shippingMethod = Object.assign({}, this.selectedShippingMethod);
        const selectedPickupLocation = this.selectedPickupLocation;
        let model = new UpdateShippingData();
        model.shippingMethod = shippingMethod.guid;
        let deliveryAddress = new DeliveryAddressInfo();
        let enableAltDeliveryAddress = shippingMethod._useAltDeliveryAddress || this.requireAltDeliveryAddress(shippingMethod);
        // Håndter pakkeshop adresse
        if (shippingMethod.hasPickupLocationProvider && selectedPickupLocation) {
            let address = new DeliveryAddressInfo();
            address.id = selectedPickupLocation.id;
            address.name = selectedPickupLocation.name;
            address.checksum = selectedPickupLocation.checksum;
            address.zipCode = selectedPickupLocation.zipCode;
            address.city = selectedPickupLocation.city;
            address.countryCode = selectedPickupLocation.countryCode;
            address.address = selectedPickupLocation.address;
            address.address2 = selectedPickupLocation.address2;
            deliveryAddress = address;
        }
        // Sæt null i addressInfo hvis adressen ikke er mulig at udfylde
        else if (!enableAltDeliveryAddress) {
            shippingMethod.address = null;
        }
        // Lav håndtering af address på shippingMethod hvis den findes
        else if (shippingMethod.address) {
            let address = shippingMethod.address;
            Object.keys(address).map((key, index) => {
                deliveryAddress[key] = address[key];
            });
            // Sæt name com contact hvis deliveryAddressType = business
            if (shippingMethod.deliveryAddressType === AddressType.Business) {
                deliveryAddress.contact = deliveryAddress.name;
                deliveryAddress.name = deliveryAddress._companyName;
            }
        }
        // Tilføj deliveryAddress til request hvis den findes, ellers skal den sættes til null
        model.deliveryAddress = deliveryAddress && !(Object.keys(deliveryAddress).length === 0) ? deliveryAddress : null;
        CartService.updateShippingMethod(model).then(res => {
            this.$emit('go-to-payment');
            // Gå videre til payment
        }, err => {
            ErrorService.handleError(err);
        });
    }
    mounted() {
        // init shipping methods
        this.getShippingMethods();
    }
    // Shipping methods
    getShippingMethods() {
        CartService.getShippingMethods().then(shippingMethods => {
            this.shippingMethods = shippingMethods;
            this.initShippingMethods();
            this.loading = false;
        }, err => {
            this.loading = false;
            ErrorService.handleError(err);
            this.$router.push("cart");
        });
    }
    initShippingMethods() {
        // Vis udfyldte felter, hvis shippingMethod er selected og der er data i felterne i forvejen
        if (this.shippingMethods) {
            for (var i = 0; i < this.shippingMethods.length; i++) {
                let item = this.shippingMethods[i];
                if (item.selected) {
                    if (item.address) {
                        item._useAltDeliveryAddress = true;
                        // Byt rundt på contact og name ift. type: Business
                        if (item.deliveryAddressType == AddressType.Business) {
                            item.address._companyName = item.address.name;
                            item.address.name = item.address.contact;
                        }
                    }
                    else if (!item.address && this.requireAltDeliveryAddress(item)) {
                        item.address = new AddressInfo();
                    }
                    this.$emit('select', item);
                    break;
                }
            }
            ;
        }
    }
    selectShippingMethod(option) {
        this.selectedPickupLocation = null;
        if (option.hasPickupLocationProvider) {
            // this.getPickupLocations(option);
        }
        else if (option.allowAlternativeDeliveryAddress) {
            if (this.requireAltDeliveryAddress(option)) {
                option.address = new AddressInfo();
            }
        }
        // Sæt korrekt selected state
        this.shippingMethods.map(x => {
            x.selected = x.guid === option.guid;
            return;
        });
        this.$emit('select', option);
    }
    toggleAltDeliveryAddress(option) {
        if (option._useAltDeliveryAddress && !option.address) {
            option.address = new AddressInfo();
        }
        else {
            this.$forceUpdate();
        }
    }
    isShippingMethodSelected(method) {
        return this.selectedShippingMethod?.guid === method.guid || method.selected === true;
    }
    requireCompanyName(method) {
        // Hvis addressType er business skal company name være required
        return method.deliveryAddressType === AddressType.Business;
    }
    requireAltDeliveryAddress(method) {
        // Hvis addressType er business skal company name være required
        return method.billingAddressType !== method.deliveryAddressType;
    }
    onPickupLocationSelect(option) {
        this.selectedPickupLocation = option;
    }
    goToPayment() {
        let shippingMethod = Object.assign({}, this.selectedShippingMethod);
        this.updateShippingMethod();
    }
};
CheckoutShippingMethods = __decorate([
    Component({
        components: {
            PickupLocations
        },
        props: {
            selectedShippingMethod: {
                type: Object
            },
            cart: Object
        }
    })
], CheckoutShippingMethods);
export default CheckoutShippingMethods;
