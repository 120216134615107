var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Datepicker from "vuejs-datepicker";
import { da, en, de } from "vuejs-datepicker/dist/locale";
import { AdmissionService } from "../../services/AdmissionService";
import CartService from "../../services/CartService";
import { TicketType } from "../../models/ticket/Ticket";
import { CartLineInsert } from "../../models/cart/Cart";
import { WebshopConfig } from '../../config';
import TicketEventTimes from './ticket-event-times/TicketEventTimes.vue';
import TicketList from './TicketList.vue';
import Message, { MessageState, StatusMessage } from "../utilities/Message.vue";
// Services
import WebshopEvents from "../../utilities/WebshopEvents";
import { EventTypes } from "../../models/EventTypes";
import { ErrorService } from "../../services/ErrorService";
// Filters
import '../../filters/dates';
let TicketConfigurator = class TicketConfigurator extends Vue {
    constructor() {
        super(...arguments);
        this.config = WebshopConfig;
        this.loading = true;
        this.loadingDates = true;
        this.loadingTickets = false;
        this.hideCalendar = true;
        this.datepickerLocales = {
            "da": da,
            "en": en,
            "de": de
        };
        this.datepickerLocale = da; // DA som default
        this.date = new Date(Date.now());
        this.openDate = null;
        this.selectedDate = null;
        this.validDates = [];
        this.ticketType = null;
        this.disabledDates = [];
        this.activeDateMonth = null;
        this.activeDateYear = null;
        this.tickets = null;
        this.times = null;
        this.selectedEventTime = null;
        this.selectEventTimeDisabled = false;
        this.selectedTickets = [];
        this.messages = [];
    }
    mounted() {
        // Sæt start dato i kalenderne til den første dag i indeværende måned
        this.date.setDate(1);
        // Init dates
        if (this.ticketIds && this.ticketIds.length > 0) {
            this.initDates();
        }
        // Init locale
        let _locale = this.datepickerLocales[WebshopConfig.culture];
        if (_locale) {
            this.datepickerLocale = _locale;
        }
    }
    get disableDateSelection() {
        return this.autoSelectDate ?? false;
    }
    get checkoutUrl() {
        return this.config.paths.cart;
    }
    get hasTickets() {
        if (this.ticketIds && this.ticketIds.length > 0) {
            return true;
        }
        return false;
    }
    get enableAddToCart() {
        if (this.selectedTickets && this.selectedTickets.length > 0) {
            return true;
        }
        return false;
    }
    get selectedTicketCount() {
        let c = 0;
        if (this.selectedTickets) {
            this.selectedTickets.forEach(x => {
                if (x.quantity)
                    c += x.quantity;
            });
        }
        return c;
    }
    get buyButtonLabel() {
        return Vue.filter("translate")('webshopTicketAddTickets');
    }
    initDates() {
        this.loading = true;
        this.loadingDates = true;
        // Sæt start dato for datepicker til at være dags dato-1 for at sikre, så dags dato bliver muligt at vælge i datepicker
        const currentDate = new Date();
        const prior = new Date().setDate(currentDate.getDate() - 1);
        this.date = new Date(prior);
        /*
          Hvis der ikke er billetter, bliver komponentet aldrig kaldt.
        */
        if (this.hasTickets) {
            AdmissionService.getAvailableDatesByAdmissionIds(this.ticketIds, this.activeDateYear, this.activeDateMonth)
                .then((res) => {
                this.ticketType = res.subtype;
                // Object.assign(this.validDates, res.dates);
                this.validDates = [...res.dates];
                // Sæt active year / month variabler
                this.activeDateMonth = res.month - 1;
                this.activeDateYear = res.year;
                // Sæt openDate ved første kald
                if (!this.openDate) {
                    let _openDate = null;
                    if (res.dates?.length > 0) {
                        let _firstDate = res.dates[0];
                        this.openDate = new Date(_firstDate);
                    }
                    if (_openDate) {
                        this.date = _openDate;
                    }
                }
                if (this.autoSelectDate) {
                    this._selectDate(this.openDate);
                }
                else {
                    this.hideCalendar = false;
                }
                this.loading = false;
                this.loadingDates = false;
            })
                .catch((err) => {
                this.loading = false;
                this.loadingDates = false;
                ErrorService.handleError(err.response);
                this.messages.push(new StatusMessage(MessageState.Danger, Vue.filter("translate")("webshopTicketNoProductsAvailable")));
            });
        }
    }
    initTickets() {
        this.loadingTickets = true;
        AdmissionService.getTicketsByDate(this.ticketIds, this.UTCDate).then(res => {
            if (res.subtype == TicketType.Event) {
                let _times = res.times;
                // Auto vælg tidspunkt, hvis der kun er ét
                if (_times && _times.length == 1) {
                    let _time = _times[0];
                    this.selectEventTimeDisabled = true;
                    this.onSelectEventTime(_time);
                }
                else {
                    this.selectEventTimeDisabled = false;
                    this.times = _times;
                }
            }
            else if (res.subtype == TicketType.Entrance) {
                this.tickets = res.tickets;
            }
            this.toggleLoadingTickets();
        }, err => {
            setTimeout(() => {
                ErrorService.handleError(err.response);
            }, 500);
            this.toggleLoadingTickets();
        });
    }
    onSelectEventTime(eventTime) {
        this.selectedTickets = [];
        this.selectedEventTime = eventTime;
        this.loadingTickets = true;
        if (eventTime != null) {
            AdmissionService.getTicketsByEvent(this.ticketIds, this.UTCDate, eventTime.id).then(res => {
                this.tickets = res.tickets;
                this.toggleLoadingTickets();
            }, err => {
                ErrorService.handleError(err.response);
                this.toggleLoadingTickets();
            });
        }
        else {
            this.selectedEventTime = null;
            this.tickets = null;
            this.loadingTickets = false;
        }
    }
    toggleLoadingTickets() {
        setTimeout(() => {
            this.loadingTickets = false;
        }, 500);
    }
    onUpdateSelectedTickets(selectedTickets) {
        this.selectedTickets = selectedTickets;
    }
    _selectDate(payload) {
        this.selectedDate = payload;
        this.hideCalendar = true;
        // Get tickets
        if (this.selectedDate && this.UTCDate) {
            this.initTickets();
        }
    }
    addToCart() {
        if (!this.loading) {
            let _cartLines = [];
            this.selectedTickets.forEach(x => {
                if (x.quantity > 0) {
                    let dateData = {};
                    if (x.time) {
                        dateData.id = x.time.id;
                    }
                    else if (x.ticket && x.ticket.time && x.ticket.time.startDate) {
                        dateData.startDate = x.ticket.time.startDate;
                    }
                    let _cartLine = new CartLineInsert(x.ticket.id, x.quantity, dateData);
                    // Tracking data
                    _cartLine.name = x.ticket.name;
                    // mangler price
                    _cartLines.push(_cartLine);
                }
            });
            this.loading = true;
            CartService.createLine(_cartLines).then((res) => {
                let cartModalObj = {
                    relatedEvents: res.events ? res.events : null,
                    date: res.date ? res.date : null
                };
                WebshopEvents.$emit(EventTypes.UpdateCartMini);
                WebshopEvents.$emit(EventTypes.CartMiniShowMessage, cartModalObj);
                // Close drawer
                this.$emit('close');
                this.loading = false;
            })
                .catch((err) => {
                this.loading = false;
                ErrorService.handleError(err.response);
            });
        }
    }
    resetSelectedDate() {
        if (this.selectedDate && !this.disableDateSelection) {
            this._reset();
        }
    }
    _reset() {
        this.selectedDate = null;
        this.hideCalendar = false;
        this.selectedTickets = [];
        this.tickets = null;
        this.times = null;
        this.selectedEventTime = null;
        this.hideCalendar = true;
        this.clearMessages();
        // TODO:
        // Clear alt og lav et initDates helt forfra
        // this._setActiveMonth(this.openDate);
        this.clearActiveDates();
    }
    clearActiveDates() {
        this.activeDateMonth = null;
        this.activeDateYear = null;
        this.initDates();
    }
    setActiveMonth(date) {
        let _month = 0;
        let _year = 0;
        let resolveDate = function (date) {
            // Hvis der bliver valgt en specifik måned i datepicker, vil den returnere et object
            if (date.timestamp) {
                return new Date(date.timestamp);
            }
            else {
                return date;
            }
        };
        this.selectedTickets = [];
        let _date = resolveDate(date);
        this.activeDateMonth = _date.getMonth();
        this.activeDateYear = _date.getFullYear();
        this.initDates();
    }
    get UTCDate() {
        return this.selectedDate
            ? Vue.filter("formatDateUTC")(this.selectedDate)
            : null;
    }
    get disabledDatesForActiveMonth() {
        let dates = [];
        let _year = this.activeDateYear;
        let _month = this.activeDateMonth;
        if ((_month || _month === 0) && _year) {
            let _startDate = new Date(_year, _month, 1);
            let _currentMonth = _startDate.getMonth();
            let i = 1;
            let isCurrentMonth = true;
            while (isCurrentMonth) {
                let _date = new Date(_year, _month, i);
                let _dateMonth = _date.getMonth();
                // Break ved månedsskift, da vi kun skal have date i indeværende måned
                if (_dateMonth != _currentMonth) {
                    break;
                }
                if (!AdmissionService.isOpenDate(_date, this.validDates)) {
                    dates.push(_date);
                }
                i++;
            }
        }
        return dates;
    }
    /* isDateOpen er flyttet til AdmissionService.isOpenDate */
    clearMessages() {
        this.messages = [];
    }
};
TicketConfigurator = __decorate([
    Component({
        components: {
            Datepicker,
            TicketEventTimes,
            TicketList,
            Message
        },
        props: {
            ticketIds: Array,
            pickDateLabel: String,
            pickTicketsLabel: String,
            pickTimeLabel: String,
            autoSelectDate: Boolean
        },
    })
], TicketConfigurator);
export default TicketConfigurator;
