import { Api } from "@jcd-web/basic-api";
export class OrderApi extends Api {
    static getOrder(path) {
        return OrderApi.client.get(path);
    }
    static downloadTickets(orderId, orderGuid) {
        return OrderApi.client.request({
            url: `${orderId}/tickets/download?guid=${orderGuid}`,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: { 'Content-Disposition': 'attachment; filename="file.pdf"' }
        });
    }
}
OrderApi.baseUrl = "/api/orders/";
