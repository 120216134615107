var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import VueTinySlider from "vue-tiny-slider";
let ContentSlider = class ContentSlider extends Vue {
    constructor() {
        super(...arguments);
        this.tinySliderActivityOptions = {
            mouseDrag: true,
            loop: true,
            rewind: true,
            arrowKeys: true,
            items: 1,
            responsive: {
                640: {
                    gutter: 20,
                    items: 2,
                },
                900: {
                    gutter: 40,
                    items: 3
                },
                1240: {
                    controls: true
                }
            },
            controls: false,
            startIndex: 0,
            nav: true,
            controlsText: [
                '<i class="mdi mdi-chevron-left"></i>',
                '<i class="mdi mdi-chevron-right"></i>'
            ]
        };
    }
};
ContentSlider = __decorate([
    Component({
        components: {
            "tiny-slider": VueTinySlider
        }
    })
], ContentSlider);
export default ContentSlider;
