var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Donation } from "./Models";
import { DonationService } from "./DonationService";
import { ToastService } from "@jcd-web/basic-toast";
let DonationForm = class DonationForm extends Vue {
    constructor() {
        super(...arguments);
        this.donation = new Donation();
        this.acceptTerms = false;
        this.stepActive = 1;
        this.loading = false;
        this.termsError = null;
        this.ErrorTimer = null;
    }
    get disableCPR() {
        return (this.donation.cvr &&
            this.donation.cvr !== "" &&
            this.donation.cvr.length > 0);
    }
    get disableCVR() {
        return (this.donation.cpr &&
            this.donation.cpr !== "" &&
            this.donation.cpr.length > 0);
    }
    get minDonationAmount() {
        if (this.minAmount == 0) {
            return 1;
        }
        return this.minAmount;
    }
    get termsAcceptText() {
        return Vue.filter('translate')("DonationConfirmationAcceptTermsText");
    }
    get newsletterAcceptText() {
        return Vue.filter('translate')("DonationFormAcceptNewsletter");
    }
    emailToLowerCase() {
        if (this.donation.email) {
            this.donation.email = this.donation.email.toLowerCase();
        }
    }
    setStep(step) {
        this.stepActive = step;
    }
    confirmData() {
        if (this.donation.amount != null) {
            this.setStep(2);
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            var element = document.getElementById("donationWrapper");
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                });
            }
        }
    }
    sendDonation() {
        if (!this.acceptTerms) {
            ToastService.warning(Vue.filter("translate")("DonationConfirmationAcceptTermsWarning"), 2500);
        }
        else {
            this.loading = true;
            DonationService.donate(this.donation)
                .then((res) => {
                this.payment(res.data);
            })
                .catch((err) => {
                this.loading = false;
            });
        }
    }
    payment(checkoutData) {
        var paymentForm = document.createElement("form");
        paymentForm.action = checkoutData.url;
        paymentForm.method = "POST";
        for (var prop in checkoutData.parameters) {
            var paymentInput = document.createElement("input");
            paymentInput.type = "hidden";
            paymentInput.name = prop.toLowerCase();
            paymentInput.value = checkoutData.parameters[prop];
            paymentForm.appendChild(paymentInput);
        }
        document.body.appendChild(paymentForm);
        paymentForm.submit();
    }
};
DonationForm = __decorate([
    Component({
        props: {
            minAmount: {
                type: Number,
                default: 1
            },
            acceptMarketing: {
                type: Boolean,
                default: false
            }
        }
    })
], DonationForm);
export default DonationForm;
