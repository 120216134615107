var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.active && !_vm.giftCardResponse
        ? [
            !_vm.show
              ? _c(
                  "button",
                  {
                    staticClass:
                      "button secondary contained medium full icon-left",
                    on: {
                      click: function($event) {
                        return _vm.toggleForm()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-wallet-giftcard icon" }),
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm._f("translate")("WebshopCheckoutGiftCardButton")
                        ) +
                        "\n\t\t"
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.show && !_vm.giftCardResponse
              ? _c(
                  "form",
                  {
                    staticClass: "gift-card-wrapper form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addGiftCard()
                      }
                    }
                  },
                  [
                    _c("label", { staticClass: "field" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.redeemGiftCard.id,
                            expression: "redeemGiftCard.id"
                          }
                        ],
                        ref: "giftCardInput",
                        attrs: {
                          type: "text",
                          placeholder: " ",
                          required: "",
                          disabled: _vm.loading
                        },
                        domProps: { value: _vm.redeemGiftCard.id },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.redeemGiftCard,
                              "id",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { attrs: { placeholder: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "WebshopCheckoutGiftCardAddField"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.loading ? _c("loader") : _vm._e(),
                    _vm._v(" "),
                    !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass: "button primary medium contained full",
                            attrs: { type: "submit" }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm._f("translate")(
                                    "WebshopCheckoutGiftCardAddButton"
                                  )
                                ) +
                                "\n\t\t\t"
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.giftCardResponse
        ? _c("message", { attrs: { state: "success", icon: "check" } }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(_vm._f("translate")("WebshopCheckoutGiftCardActive"))
            ),
            _c("br"),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("translate")("WebshopCheckoutGiftCardBalance")) +
                  ": " +
                  _vm._s(_vm.giftCardResponse.balance)
              )
            ]),
            _c("br"),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.removeGiftCard()
                  }
                }
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm._f("translate")("WebshopCheckoutGiftCardRemoveButton")
                    ) +
                    "\n\t\t"
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.active && !_vm.giftCardResponse
        ? _c(
            "message",
            { attrs: { state: "success", icon: "wallet-giftcard" } },
            [
              _vm._v(
                "\n\t\t" +
                  _vm._s(_vm._f("translate")("WebshopCheckoutGiftCardActive"))
              ),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.removeGiftCard()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(
                        _vm._f("translate")(
                          "WebshopCheckoutGiftCardRemoveButton"
                        )
                      ) +
                      "\n\t\t"
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }