/*
    Load components for products:
        -   ProductsByCategory
        -   ProductDetails
*/
import Vue from 'vue';
const ProductsByCategory = () => ({
    component: import(/* webpackChunkName: 'ProductsByCategory' */ './ProductsByCategory.vue')
});
Vue.component('products-by-category', ProductsByCategory);
const ProductDetails = () => ({
    component: import(/* webpackChunkName: 'ProductDetails' */ './ProductDetails.vue')
});
Vue.component('product-details', ProductDetails);
