var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { UserService } from "../../services/UserService";
import { WebshopConfig } from "../../config";
let UserMiniMe = class UserMiniMe extends Vue {
    constructor() {
        super(...arguments);
        this.config = WebshopConfig;
        this.loggedIn = WebshopConfig.user && WebshopConfig.user.loggedIn;
        this.username = "";
    }
    get label() {
        return this.config.user.label;
    }
    get labelActive() {
        return this.config.user.labelActive;
    }
    mounted() {
        if (this.loggedIn) {
            UserService.get().then((user) => {
                this.username = this.getStructuredName(user.name);
            });
        }
    }
    getStructuredName(val) {
        if (val != null) {
            var arr = val.split(" ");
            return arr.length > 1 ? `${arr[0]} ${arr[arr.length - 1][0]}.` : arr[0];
        }
    }
    get loginUrl() {
        return WebshopConfig.paths ? WebshopConfig.paths.login : "";
    }
    logout() {
        UserService.logout().then(() => {
            window.location.reload();
        });
    }
};
UserMiniMe = __decorate([
    Component({
        props: {
            isMobile: Boolean
        }
    })
], UserMiniMe);
export default UserMiniMe;
