export class CustomResponse {
}
export class ResponseData {
}
export var ErrorCodes;
(function (ErrorCodes) {
    // Checkout
    ErrorCodes["CheckoutTicketOutOfStock"] = "CheckoutTicketOutOfStock";
    // Login
})(ErrorCodes || (ErrorCodes = {}));
