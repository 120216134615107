var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import GalleryWithTextAndImageSlide from "components/grid/GalleryWithTextAndImage/GalleryWithTextAndImageSlide.vue";
let GalleryWithTextAndImage = class GalleryWithTextAndImage extends Vue {
    constructor() {
        super(...arguments);
        this.currentIndex = 1;
    }
    mounted() {
        // console.log("outer");
    }
    gotoSlide(slide) {
        this.currentIndex = slide;
    }
    nextSlide() {
        if (this.currentIndex < this.slides) {
            this.currentIndex++;
        }
        else {
            this.currentIndex = 1;
        }
    }
    prevSlide() {
        if (this.currentIndex > 1) {
            this.currentIndex--;
        }
        else {
            this.currentIndex = this.slides;
        }
    }
};
GalleryWithTextAndImage = __decorate([
    Component({
        components: {
            GalleryWithTextAndImageSlide
        },
        props: {
            slides: Number
        }
    })
], GalleryWithTextAndImage);
export default GalleryWithTextAndImage;
