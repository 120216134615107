import { Api } from '@jcd-web/basic-api';
export class EventApi extends Api {
    static getlist(id, culture) {
        let params = {};
        if (culture)
            params.culture = culture;
        return this.client.get("/list/" + id.toString(), { params: params });
    }
}
EventApi.baseUrl = '/api/event';
