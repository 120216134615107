var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.showModal,
        customClass: "cart-mini-modal theme-white"
      },
      on: { close: _vm.closeMessage }
    },
    [
      _vm.cartModal
        ? _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm.cartModal.message
                ? [
                    _c("div", { staticClass: "cart-mini-modal__header" }, [
                      _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.cartModal.message.message
                        ? _c("h3", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.cartModal.message.message))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cart-mini-modal-content" }, [
                      _vm.cartModal.message.lines
                        ? _c(
                            "ul",
                            { staticClass: "cart-mini-modal-lines" },
                            _vm._l(_vm.cartModal.message.lines, function(
                              line,
                              $index
                            ) {
                              return _c("li", { key: $index }, [
                                line.icon
                                  ? _c("span", { staticClass: "icon" }, [
                                      _c("i", {
                                        staticClass: "mdi",
                                        class: "mdi-" + line.icon
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "quantity" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(line.quantity) +
                                      " x\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(line.name) +
                                      "\n            "
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.cartModal && _vm.cartModal.relatedEvents
                ? [
                    _c("hr", { staticClass: "cart-mini-modal__divider" }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.relatedHeadline()))
                    ]),
                    _vm._v(" "),
                    _c("related-events", {
                      attrs: { "related-events": _vm.cartModal.relatedEvents }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("footer", { staticClass: "cart-mini-modal-footer" }, [
                _c(
                  "a",
                  {
                    staticClass: "button contained",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeMessage.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("translate")("WebshopMiniCartContinueShopping")
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button contained primary",
                    attrs: { href: _vm.cartPage }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("translate")("WebshopMiniCartGoToCart")) +
                        "\n      "
                    )
                  ]
                )
              ])
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }