var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "user-mini-me site-header__shortcut-item",
      class: {
        "site-header__shortcut-item--contained": _vm.loggedIn,
        "site-header__shortcut-item--hide-mobile": !_vm.isMobile
      },
      attrs: { href: _vm.loginUrl }
    },
    [
      _c(
        "svg",
        {
          staticClass: "site-header__shortcut-item-icon",
          attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z"
            }
          })
        ]
      ),
      _vm._v("\n\t" + _vm._s(this.username) + "\n\t"),
      _vm.labelActive && !_vm.loggedIn
        ? _c("label", [_vm._v(_vm._s(_vm.label))])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }