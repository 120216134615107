import Vue from 'vue';
const WebshopEvents = new Vue();
export default WebshopEvents;
export var WebshopEventTypes;
(function (WebshopEventTypes) {
    // Checkout - cart
    WebshopEventTypes["TicketOutOfStock"] = "ticket-out-of-stock";
    WebshopEventTypes["CheckoutUpdateCart"] = "checkout-update-cart";
    WebshopEventTypes["CheckoutShowProgress"] = "checkout-show-progress";
    // Checkout - shipping
    WebshopEventTypes["PickupLocationInit"] = "pickup-location-init";
    WebshopEventTypes["ShippingMethodUpdate"] = "shipping-method-update";
    // Admission
    // Admission - cards
    WebshopEventTypes["AdmissionCardFormShow"] = "admission-card-form-show";
    WebshopEventTypes["AdmissionCardFormHide"] = "admission-card-form-hide";
    WebshopEventTypes["AdmissionCardScanAll"] = "admission-card-scan-all";
    WebshopEventTypes["CheckoutPromotionCodeUpdate"] = "checkout-promotion-code-update";
})(WebshopEventTypes || (WebshopEventTypes = {}));
