var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "redeem-promotion-code" }, [
    _c(
      "div",
      { staticClass: "redeem-promotion-code__inner" },
      [
        !_vm.hasActivePromotionCode
          ? [
              !_vm.hasActivePromotionCode && _vm.allowPromotionCode
                ? _c("div", { staticClass: "redeem-promotion-code__wrapper" }, [
                    _c("label", { attrs: { for: "activatePromoCode" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "WebshopCheckoutPromotionCodeText"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.showPromotionCodeField,
                          expression: "showPromotionCodeField"
                        }
                      ],
                      attrs: { type: "checkbox", id: "activatePromoCode" },
                      domProps: {
                        checked: Array.isArray(_vm.showPromotionCodeField)
                          ? _vm._i(_vm.showPromotionCodeField, null) > -1
                          : _vm.showPromotionCodeField
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.showPromotionCodeField,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.showPromotionCodeField = $$a.concat([
                                    $$v
                                  ]))
                              } else {
                                $$i > -1 &&
                                  (_vm.showPromotionCodeField = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.showPromotionCodeField = $$c
                            }
                          },
                          function($event) {
                            return _vm.focus()
                          }
                        ]
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showPromotionCodeField && !_vm.hasActivePromotionCode
                ? _c(
                    "form",
                    {
                      staticClass: "redeem-promotion-code__form form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.validatePromotionCode()
                        }
                      }
                    },
                    [
                      _c("label", { staticClass: "field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.promotionCode.id,
                              expression: "promotionCode.id"
                            }
                          ],
                          ref: "promotionCodeInput",
                          attrs: {
                            type: "text",
                            disabled: _vm.loading,
                            required: ""
                          },
                          domProps: { value: _vm.promotionCode.id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.promotionCode,
                                "id",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { attrs: { placeholder: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "WebshopCheckoutPromotionCodeAddField"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.loading ? _c("loader") : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading
                        ? _c(
                            "button",
                            {
                              staticClass: "button secondary contained small",
                              attrs: { type: "submit" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "WebshopCheckoutPromotionCodeAddButton"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          : _c("div", { staticClass: "redeem-promotion-code-message" }, [
              _c(
                "div",
                { staticClass: "redeem-promotion-code-message__inner" },
                [
                  _c("div", [
                    _c(
                      "svg",
                      {
                        staticClass: "redeem-promotion-code-message__icon",
                        attrs: { viewBox: "0 0 24 24" }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "redeem-promotion-code-message__text" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "redeem-promotion-code-message__label"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "WebshopCheckoutPromotionCodeLabel"
                                )
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "redeem-promotion-code-message__text--code"
                          },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.promotionCode.code) +
                                  " \n                                "
                              ),
                              _vm.promotionCode.discountPercentage
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "redeem-promotion-code-message__text--discount"
                                    },
                                    [
                                      _vm._v(
                                        "(-" +
                                          _vm._s(
                                            _vm.promotionCode.discountPercentage
                                          ) +
                                          "%)"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.allowPromotionCode
                    ? _c(
                        "a",
                        {
                          staticClass: "redeem-promotion-code-message__remove",
                          attrs: { type: "button", href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removePromotionCode()
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "currentColor",
                                  d:
                                    "M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }