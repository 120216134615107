var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let EventTime = class EventTime extends Vue {
    /*
      Event:
        - Altid startTime
        - Altid startDate
        - ?endTime
  
      Enstrance:
        - Hvis startTime - vis start
        - Hvis endTime - vis end
    */
    get dateDisplay() {
        let date = "";
        if (this.model.startDate) {
            date += Vue.filter('formatLongDate')(this.model.startDate);
        }
        return date;
    }
    get timeDisplay() {
        let result = "";
        const _time = this.model;
        if (_time) {
            if (_time.start) {
                let _start = this.formatTime(_time.start);
                result += `  ${_start ? _start : _time.start}`;
            }
            if (_time.end) {
                let _end = this.formatTime(_time.end);
                result += ` - ${_end ? _end : _time.end}`;
            }
        }
        return result;
    }
    formatTime(time) {
        let _time = time ? time.split(":") : null;
        if (_time && _time.length >= 2) {
            let date = new Date();
            try {
                date.setHours(Number(_time[0]));
                date.setMinutes(Number(_time[1]));
                return Vue.filter('formatHour')(date);
            }
            catch {
                // no-handler
            }
        }
        return "";
    }
};
EventTime = __decorate([
    Component({
        props: {
            model: Object,
            showDate: Boolean
        }
    })
], EventTime);
export default EventTime;
