export var EventTypes;
(function (EventTypes) {
    // Checkout
    EventTypes["goToPayment"] = "go-to-payment";
    EventTypes["setCheckOutObject"] = "set-check-out-object";
    // Checkout - cart
    EventTypes["TicketOutOfStock"] = "ticket-out-of-stock";
    // Payload
    EventTypes["updatePayload"] = "update-payload";
    // Search
    EventTypes["loading"] = "loading";
    EventTypes["searchResults"] = "search-results";
    EventTypes["loadMore"] = "load-more";
    EventTypes["setCurrentPage"] = "set-currentpage";
    // Cart & AdmissionCard
    EventTypes["UpdateStep"] = "update-step";
    EventTypes["UpdateCart"] = "checkout-update-cart";
    EventTypes["UpdateCartMini"] = "update-cart-mini";
    EventTypes["UpdateInformation"] = "checkout-update-information";
    EventTypes["UpdateDelivery"] = "checkout-update-delivery";
    EventTypes["UpdateSummary"] = "update-admissioncards-summary";
    EventTypes["AdmissionCardUpdateCart"] = "admission-card-update-cart";
    EventTypes["AdmissionAddGiftCardSuccess"] = "admission-add-gift-card-success";
    EventTypes["AdmissionAddGiftCardError"] = "admission-add-gift-card-error";
    EventTypes["CartMiniShowMessage"] = "cart-mini-show-message";
})(EventTypes || (EventTypes = {}));
