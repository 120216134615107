var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.relatedEvents
    ? _c(
        "div",
        { staticClass: "related-events" },
        [
          _c(
            "vue-tiny-slider",
            _vm._b(
              { ref: "slider" },
              "vue-tiny-slider",
              _vm.tinySliderOptions,
              false
            ),
            _vm._l(_vm.relatedEvents, function(relatedEvent, index) {
              return _c(
                "div",
                { key: index, staticClass: "related-events-slider-item" },
                [
                  _c("related-event-item", {
                    attrs: { "related-event": relatedEvent }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }