var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ticket-event-item",
      class: { disabled: _vm.isOutOfStock },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.select(_vm.item)
        }
      }
    },
    [
      _c("div", { staticClass: "timespan" }, [
        _c("span", { staticClass: "time time-from" }, [
          _vm._v("\n        " + _vm._s(_vm.item.start) + "\n      ")
        ]),
        _vm._v("\n      -\n      "),
        _c("span", { staticClass: "time time-to" }, [
          _vm._v("\n        " + _vm._s(_vm.item.end) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _vm.item.availableCapacity || _vm.isOutOfStock
        ? _c(
            "div",
            {
              staticClass: "capacity",
              class: { "sold-out": _vm.isOutOfStock }
            },
            [
              _c("i", { staticClass: "mdi mdi-account" }),
              _vm._v(" "),
              _vm.isOutOfStock
                ? [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("translate")("webshopTicketSoldOut")) +
                        "\n      "
                    )
                  ]
                : [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshopTicketTypeEventAvailableTickets",
                            _vm.item.availableCapacity
                          )
                        ) +
                        "\n      "
                    )
                  ]
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }