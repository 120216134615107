var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import RelatedEventItem from '../related/RelatedEventItem.vue';
import VueTinySlider from "vue-tiny-slider";
let RelatedEvents = class RelatedEvents extends Vue {
    constructor() {
        super(...arguments);
        this.slider = {};
        this.tinySliderOptions = {
            mouseDrag: true,
            loop: false,
            rewind: false,
            gutter: 30,
            autoplay: false,
            arrowKeys: true,
            items: 1,
            controls: true,
            controlsText: [
                '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" /></svg>',
                '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>'
            ],
            nav: false,
            autoplayButton: false,
            startIndex: 0,
            responsive: {
                576: {
                    gutter: 30,
                    items: 2
                }
            },
        };
    }
    mounted() {
        this.slider = this.$refs.slider;
    }
};
RelatedEvents = __decorate([
    Component({
        props: {
            relatedEvents: []
        },
        components: {
            RelatedEventItem,
            VueTinySlider
        }
    })
], RelatedEvents);
export default RelatedEvents;
