import { Service } from "@jcd-web/basic-service";
import { AdmissionApi } from "../api/AdmissionApi";
import Vue from 'vue';
import dayjs from 'dayjs';
export class AdmissionService extends Service {
    static getAvailableDatesByEvent(id) {
        return new Promise((resolve, reject) => {
            AdmissionApi.getAvailableDatesByEvent(id).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static getAvailableDatesByAdmissionIds(ids, year, month) {
        return new Promise((resolve, reject) => {
            AdmissionApi.getAvailableDatesByAdmissionIds(ids, year, month).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static getByDateAndAdmissionIds(ids, date) {
        return new Promise((resolve, reject) => {
            AdmissionApi.getByDateAndAdmissionIds(ids, date).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static getTicketsByDate(tickets, date) {
        let _tickets = tickets.join(';');
        return new Promise((resolve, reject) => {
            AdmissionApi.getTicketsByDate(_tickets, date).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // /api/admission/tickets/B0032;B0033/dates/2020-10-02/events/{E291CD86-8894-4353-A871-F4297A3ABB8A}
    static getTicketsByEvent(tickets, date, event) {
        let _tickets = tickets.join(';');
        return new Promise((resolve, reject) => {
            AdmissionApi.getTicketsByEvent(_tickets, date, event).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // Admission Cards
    static getAdmissionCardDefinitions(ids) {
        return new Promise((resolve, reject) => {
            if (ids) {
                AdmissionApi.getAdmissionCardDefinitions(ids).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }
    static getMonthTranslation() {
        let arr = [
            Vue.filter("translate")("webshopGeneralDatepickerJanuary"),
            Vue.filter("translate")("webshopGeneralDatepickerFebruary"),
            Vue.filter("translate")("webshopGeneralDatepickerMarch"),
            Vue.filter("translate")("webshopGeneralDatepickerApril"),
            Vue.filter("translate")("webshopGeneralDatepickerMay"),
            Vue.filter("translate")("webshopGeneralDatepickerJune"),
            Vue.filter("translate")("webshopGeneralDatepickerJuly"),
            Vue.filter("translate")("webshopGeneralDatepickerAugust"),
            Vue.filter("translate")("webshopGeneralDatepickerSeptember"),
            Vue.filter("translate")("webshopGeneralDatepickerOctober"),
            Vue.filter("translate")("webshopGeneralDatepickerNovember"),
            Vue.filter("translate")("webshopGeneralDatepickerDecember")
        ];
        return arr;
    }
    // Utils
    static isOpenDate(date, validDates) {
        /*
         Lav sammenligning med validDates for at tjekke om den pågældende dato findes i validDates array'et
       */
        let format = "YYYY-MM-DD";
        let formattedDate = dayjs(date).format(format);
        for (let i = 0; i < validDates.length; i++) {
            let validDate = dayjs(validDates[i]).format(format);
            if (validDate === formattedDate) {
                return true;
                break;
            }
        }
        return false;
    }
    static isRenewable(admissionCard) {
        /*
          Lav vurdering om et adgangskort kan fornyes
        */
        if (admissionCard.blocked || !admissionCard.renewable) {
            return false;
        }
        if (admissionCard.active && !admissionCard.allowFutureDate) {
            return false;
        }
        return true;
    }
}
