var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-text-small" },
    [
      _vm.showLogin
        ? _c("message", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm._f("translate")(
                    "WebshopCheckoutInformationUpdateUserText"
                  )
                ) +
                "\n    "
            ),
            _c("a", { attrs: { href: _vm.myProfilePageURL } }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("translate")("WebshopCheckoutInformationUpdateUser")
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("table", { attrs: { cellpadding: "5" } }, [
        _c("tr", [
          _c("td", { staticClass: "label" }, [
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                _vm._s(_vm._f("translate")("WebshopCheckoutInformationName"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.model.name))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "label" }, [
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                _vm._s(_vm._f("translate")("WebshopCheckoutInformationAddress"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.model.address))])
        ]),
        _vm._v(" "),
        _vm.model.address2
          ? _c("tr", [
              _c("td", { staticClass: "label" }, [
                _c("span", { attrs: { placeholder: "" } }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")("WebshopCheckoutInformationAddress2")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.model.address2))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "label" }, [
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                _vm._s(_vm._f("translate")("WebshopCheckoutInformationZipcode"))
              )
            ]),
            _vm._v("\n        &\n        "),
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm._f("translate")("WebshopCheckoutInformationCity"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.model.zipCode) + " " + _vm._s(_vm.model.city))
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "label" }, [
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm._f("translate")("WebshopCheckoutInformationCountry")
                  )
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.model.countryCode))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "label" }, [
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                _vm._s(_vm._f("translate")("WebshopCheckoutInformationEmail"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.model.email))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", { staticClass: "label" }, [
            _c("span", { attrs: { placeholder: "" } }, [
              _vm._v(
                _vm._s(_vm._f("translate")("WebshopCheckoutInformationPhone"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.model.phoneNumber))])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }