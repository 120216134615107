var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
// Services
import WebshopEvents from "webshop/utilities/WebshopEvents";
// Filters
import "webshop/filters/dates";
import "webshop/filters/translate";
// Components
import DrawerMenu from "webshop/components/utilities/DrawerMenu.vue";
import TicketConfigurator from "webshop/components/tickets/TicketConfigurator.vue";
let DrawerTicketConfigurator = class DrawerTicketConfigurator extends Vue {
    constructor() {
        super(...arguments);
        this.ticketIds = [];
        this.showDrawer = false;
        this.pickDateLabel = "";
        this.pickTicketsLabel = "";
        this.autoSelectDate = false;
    }
    get ticketConfigKey() {
        if (this.tickets) {
            let key = "";
            this.tickets.map((x) => {
                key += x;
            });
            return key;
        }
        this.tickets;
    }
    get tickets() {
        return this.ticketIds;
    }
    mounted() {
        WebshopEvents.$on("toggle-drawer", this.toggleDrawer);
    }
    toggleDrawer(data) {
        if (data) {
            this.ticketIds = data.tickets;
            this.pickDateLabel = data.pickDateLabel;
            this.pickTicketsLabel = data.pickTicketsLabel;
            if (data.autoSelectDate) {
                this.autoSelectDate = data.autoSelectDate;
            }
        }
        this.showDrawer = true;
        document.documentElement.style.overflow = 'hidden';
    }
    hideDrawer() {
        this.showDrawer = false;
        document.documentElement.style.overflow = 'auto';
    }
};
DrawerTicketConfigurator = __decorate([
    Component({
        components: {
            DrawerMenu,
            TicketConfigurator
        },
    })
], DrawerTicketConfigurator);
export default DrawerTicketConfigurator;
