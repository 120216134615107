var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let TicketEventTimes = class TicketEventTimes extends Vue {
    get isOutOfStock() {
        if (this.item.availableCapacity === 0)
            return true;
        return false;
    }
    select(item) {
        if (this.isOutOfStock) {
            return false;
        }
        this.$emit('select', item);
    }
};
TicketEventTimes = __decorate([
    Component({
        props: {
            item: Object
        }
    })
], TicketEventTimes);
export default TicketEventTimes;
