var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import WebshopEvents from "../../utilities/WebshopEvents";
import { EventTypes } from "../../models/EventTypes";
import CartService from "webshop/services/CartService";
import { ToastService } from "@jcd-web/basic-toast";
let CartLineAdmissionCard = class CartLineAdmissionCard extends Vue {
    constructor() {
        super(...arguments);
        this.showDetails = false;
    }
    get statusText() {
        return this.card.renewal ? Vue.filter('translate')('WebshopAdmissionCardLabelRenewal') : Vue.filter('translate')('WebshopAdmissionCardLabelNew');
    }
    get owner() {
        return this.card?.owner;
    }
    get ownerDetails() {
        let _details = [];
        if (this.owner) {
            for (let key in this.owner) {
                let _value = this.owner[key];
                let dictionaryKey = `WebshopAdmissionCardDetails${key[0].toUpperCase() + key.substring(1)}`;
                let _obj = {
                    key: key,
                    value: _value,
                    alias: dictionaryKey
                };
                _details.push(_obj);
            }
        }
        return _details;
    }
    toggleDetails() {
        this.showDetails = this.showDetails == false;
    }
    remove() {
        let message = Vue.filter("translate")("webshopCheckoutCartWishToDelete");
        message = message.replace("{product}", this.card.owner.firstName);
        if (window.confirm(message)) {
            // emit (item)
            CartService.deleteDataLine(this.lineGuid, this.card.guid).then(res => {
                ToastService.success(Vue.filter("translate")("webshopCheckoutCartLineDeleted"), 4000);
                WebshopEvents.$emit(EventTypes.UpdateCartMini);
                WebshopEvents.$emit(EventTypes.AdmissionCardUpdateCart);
                this.$emit('remove');
            });
        }
    }
};
CartLineAdmissionCard = __decorate([
    Component({
        components: {},
        props: {
            card: Object,
            lineGuid: String,
            lineName: String
        }
    })
], CartLineAdmissionCard);
export default CartLineAdmissionCard;
