export var ProductType;
(function (ProductType) {
    ProductType["AdmissionCard"] = "AdmissionCard";
    ProductType["Ticket"] = "Ticket";
})(ProductType || (ProductType = {}));
export var CheckoutResponseType;
(function (CheckoutResponseType) {
    CheckoutResponseType["FormPost"] = "FormPost";
    CheckoutResponseType["Redirect"] = "Redirect";
})(CheckoutResponseType || (CheckoutResponseType = {}));
export class CartLineUpdate {
}
export class CartLineInsert {
    constructor(productId, quantity, data, name, price) {
        this.productId = productId;
        this.quantity = quantity;
        this.data = data;
    }
}
export class CartDataAdmission {
}
export class RelatedEvent {
}
