var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "checkout-progress" }, [
    _c(
      "ul",
      _vm._l(_vm.stepsFiltered, function(item, $index) {
        return _c(
          "li",
          {
            key: $index,
            class: {
              active: item.index == _vm.step,
              passed: item.index < _vm.step
            }
          },
          [
            _c(
              "span",
              { staticClass: "step-count" },
              [
                !(item.index < _vm.step)
                  ? [_vm._v("\n          " + _vm._s(item.index) + "\n        ")]
                  : _c("i", { staticClass: "mdi mdi-check" })
              ],
              2
            ),
            _vm._v(" "),
            item.index < _vm.step
              ? [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        tag: "a",
                        to:
                          item.alias == "/"
                            ? "cart"
                            : item.alias.replace("/", "")
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("translate")(
                              _vm.getDictionaryKey(item.alias)
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]
              : [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("translate")(_vm.getDictionaryKey(item.alias))
                      ) +
                      "\n      "
                  )
                ]
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }