var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
// Pages
import CheckoutCart from "./CheckoutCart.vue";
import CheckoutNotFound from "./CheckoutNotFound.vue";
import CheckoutShipping from "./CheckoutShipping.vue";
import CheckoutInformation from "./CheckoutInformation.vue";
import CheckoutPayment from "./CheckoutPayment.vue";
import WebshopEvents, { WebshopEventTypes } from "webshop/utilities/WebshopEvents";
import { EventTypes } from "webshop/models/EventTypes";
// Components
import CheckoutProgress from "../../components/checkout/CheckoutProgress.vue";
// Services
import { UserService } from "webshop/services/UserService";
import { WebshopConfig } from "webshop/config";
let Checkout = class Checkout extends Vue {
    constructor() {
        super(...arguments);
        this.step = 1;
        this.currentUser = null;
        this.showProgress = true;
    }
    mounted() {
        if (WebshopConfig.user?.loggedIn) {
            UserService.get().then((res) => {
                this.currentUser = res;
            });
        }
        WebshopEvents.$on(WebshopEventTypes.CheckoutShowProgress, res => {
            this.showProgress = res;
        });
    }
    created() {
        WebshopEvents.$on(EventTypes.UpdateStep, this.updateStep);
    }
    updateStep(step) {
        this.step = step;
        this.scrollToTop();
    }
    scrollToTop() {
        window.scrollTo(0, 0); // Scroll til toppen af hvert step i checkout
    }
};
Checkout = __decorate([
    Component({
        components: {
            CheckoutProgress
        }
    })
], Checkout);
export default Checkout;
Checkout.prototype.routes = [
    {
        path: "/",
        component: CheckoutCart,
        alias: "cart"
    },
    {
        path: "information",
        component: CheckoutInformation
    },
    {
        path: "delivery",
        component: CheckoutShipping,
        alias: "delivery"
    },
    {
        path: "payment",
        component: CheckoutPayment,
        props: true
    },
    { path: "cart", redirect: "/" },
    // { path: "/", component: CheckoutCart, alias: 'cart'}
    // { path: "/delivery", component: CheckoutDelivery, alias: 'delivery', props: true },
    // { path: "/reciept", component: CheckoutReciept, alias: 'reciept', props: true },
    // { path: "/quick-order", component: CheckoutQuickOrder, alias: 'quick-order', props: true },
    { path: "*", component: CheckoutNotFound, alias: "" }
];
