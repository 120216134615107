import { Service } from '@jcd-web/basic-service';
export class GoogleService extends Service {
    static addAnalyticsEvent(event) {
        return new Promise((resolve, reject) => {
            window.dataLayer.push({
                'event': 'GAEvent',
                'eventCategory': event.category ? event.category : '',
                'eventAction': event.action ? event.action : '',
                'eventLabel': event.label ? event.label : ''
            });
            resolve();
        });
    }
}
