var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ticket
    ? _c(
        "li",
        {
          staticClass: "ticket-list-item",
          class: _vm.isSoldOut ? "ticket-list-item--sold-out" : ""
        },
        [
          _c("div", { staticClass: "ticket-list-item__inner" }, [
            _c("div", [
              _c("p", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.ticket.name))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "price-wrapper" },
                [
                  _vm.ticket.unitPrice
                    ? _c("p", { staticClass: "unit-price" }, [
                        _vm._v(_vm._s(_vm.ticket.unitPrice))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.ticket.salesPrice))
                  ]),
                  _vm._v(" "),
                  _vm.ticket.time
                    ? _c("event-time", {
                        attrs: {
                          model: _vm.ticket.time,
                          "show-date": _vm.showDate
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.quantity,
                  expression: "quantity",
                  modifiers: { number: true }
                }
              ],
              staticClass: "number-of-tickets",
              attrs: {
                min: "0",
                type: "number",
                max: _vm.currentCapacity,
                disabled: _vm.isSoldOut || _vm.disableAdd
              },
              domProps: { value: _vm.quantity },
              on: {
                change: _vm.onChange,
                focus: _vm.select,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "amount-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "add",
                  class: { disabled: _vm.disableAdd },
                  attrs: { disabled: _vm.disableAdd },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addTicket()
                    }
                  }
                },
                [_vm._v("+")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "remove",
                  class: { disabled: _vm.disableRemove },
                  attrs: { disabled: _vm.disableRemove },
                  on: {
                    click: function($event) {
                      return _vm.removeTicket()
                    }
                  }
                },
                [_vm._v("-")]
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }