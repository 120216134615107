var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "@jcd-web/basic-toast";
import CartService from "../../services/CartService";
import { CheckoutResponseType } from '../../models/cart/Cart';
import { Checkout } from "../../models/checkout/Checkout";
import { WebshopConfig } from '../../config';
//Webshop
import WebshopEvents, { WebshopEventTypes } from "../../utilities/WebshopEvents";
import { EventTypes } from "../../models/EventTypes";
import CheckoutOrderSummary from "../../components/checkout/CheckoutOrderSummary.vue";
import CheckoutNewsletterSignup from "../../components/checkout/CheckoutNewsletterSignup.vue";
import RedeemGiftCard from '../../components/giftcard/RedeemGiftCard.vue';
import "../../filters/translate";
import { ErrorService } from "../../services/ErrorService";
import CheckoutDisplayUserData from "../../components/checkout/CheckoutDisplayUserData.vue";
import CheckoutPaymentAddress from '../../components/checkout/payment/CheckoutPaymentAddress.vue';
import RedeemPromotionCode from "../../components/promotion-codes/RedeemPromotionCode.vue";
let CheckoutPayment = class CheckoutPayment extends Vue {
    constructor() {
        super(...arguments);
        //payload: Payment | null = null;
        this.payload = null;
        // checkOutObject: Checkout;
        this.paymentMethod = null;
        this.checkoutData = null;
        this.loading = false;
        this.callPayment = false;
        this.errorMessage = "";
        this.termsAccepted = false;
        this.newslettersAccepted = false;
        this.config = WebshopConfig;
        this.cart = null;
    }
    get payLabel() {
        return this.cart?.paymentRequired === true
            ? "WebshopCheckoutPaymentPay"
            : "WebshopCheckoutPaymentFree";
    }
    get enableNewsletterSubscription() {
        return WebshopConfig.ecommerce.newsletterSubscription;
        // return this.checkout.cart.cart.newsletterSubscription;
    }
    get acceptTermsDescription() {
        let result = "";
        result = Vue.filter("translate")("webshopCheckoutPaymentIAcceptDescription");
        result = result.replace("{link}", this.config.paths.terms);
        return result;
    }
    get giftCardEnabled() {
        let enabled = WebshopConfig.ecommerce.giftCardPaymentEnabled;
        /*
         Gavekort skal ikke vises hvis:
          - der ikke er påkrævet betaling på kurven
          - der ikke findes et gavekort tilknyttet i forvejen
        */
        if (enabled && (!this.cart?.paymentRequired && !this.cart?.giftCard)) {
            enabled = false;
        }
        // Ellers laves et tjek på, om gavekort er slået til for shoppen
        return enabled;
    }
    get shippingAddress() {
        return this.cart?.shipping?.address;
    }
    get shippingMethod() {
        return this.cart?.shipping?.method;
    }
    get promotionCodesEnabled() {
        return false;
    }
    getCart() {
        CartService.getCart().then(cart => {
            if (cart?.lines?.length === 0 || typeof (cart) === 'string') {
                this.$router.push("cart");
                return;
            }
            this.cart = cart;
            this.loading = false;
            WebshopEvents.$emit(WebshopEventTypes.CheckoutUpdateCart, cart);
            let progressStep = this.cart.shippingRequired ? 4 : 3;
            WebshopEvents.$emit(EventTypes.UpdateStep, progressStep);
        }, err => {
            ErrorService.handleError(err);
            this.$router.push("cart");
        });
    }
    acceptTermsMessage() {
        ToastService.warning(Vue.filter("translate")("WebshopCheckoutPaymentErrorAcceptTerms"), 3000);
    }
    goToPayment() {
        this.loading = true;
        this.callPayment = true;
        if (this.cart) {
            let _checkout = new Checkout();
            _checkout.comments = "";
            if (this.enableNewsletterSubscription) {
                _checkout.subscribeNewsletter = this.newslettersAccepted;
            }
            CartService.checkout(_checkout).then((res) => {
                if (res.type === CheckoutResponseType.FormPost) {
                    this.updatePayment(res);
                }
                else if (res.type === CheckoutResponseType.Redirect) {
                    window.location.href = String(res.url);
                }
                // TODO: Fallback til håndtering af flere typer?
                else {
                    window.location.href = String(res.url);
                }
            }, (err) => {
                ErrorService.handleError(err.response).then((errRes) => {
                    this.errorMessage = errRes;
                });
                this.loading = false;
                // this.callPayment = false;
            });
        }
    }
    mounted() {
        this.getCart();
    }
    updatePayment(data) {
        this.payload = data;
        let self = this;
        setTimeout(function () {
            let form = self.$refs.paymentForm;
            if (form) {
                form.submit();
            }
        }, 500);
    }
    onGiftCardUpdate() {
        this.getCart();
    }
};
CheckoutPayment = __decorate([
    Component({
        props: {
            currentUser: Object
        },
        components: {
            CheckoutOrderSummary,
            CheckoutPaymentAddress,
            CheckoutNewsletterSignup,
            CheckoutDisplayUserData,
            RedeemGiftCard,
            RedeemPromotionCode
        },
    })
], CheckoutPayment);
export default CheckoutPayment;
