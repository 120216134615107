export class Checkout {
}
export class CheckoutLocation {
    constructor(name, address, customerType) {
        this.name = name;
        this.address = address;
        this.customerType = customerType;
    }
}
export class AddressInfo {
}
export class DeliveryAddressInfo extends AddressInfo {
}
export class BillingAddressInfo extends AddressInfo {
}
export class CheckoutInformation {
}
// VM's
export class CheckoutVM {
    constructor(cart, information, delivery, payment, countries) {
        this.cart = cart;
        this.information = information;
        this.delivery = delivery;
        this.payment = payment;
        this.countries = countries;
    }
}
export class CheckoutCartVM {
    constructor(cart) {
        this.cart = cart;
    }
}
export class CheckoutInformationVM {
    constructor(billingAddress, information) {
        this.billingAddress = billingAddress;
        this.information = information;
    }
}
export class CheckoutDeliveryVM {
    constructor(deliveryAddress, useAltDeliveryAddress) {
        this.deliveryAddress = deliveryAddress;
        this.useAltDeliveryAddress = useAltDeliveryAddress;
    }
}
export class CheckoutPaymentVM {
}
