var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gift-card-component" },
    [
      _vm.giftCardEnabled
        ? _c(
            "card",
            [
              _vm._t("default"),
              _vm._v(" "),
              _c("hr", {}),
              _vm._v(" "),
              _c("div", [
                _vm.product
                  ? _c(
                      "form",
                      {
                        staticClass: "gift-card-component-form",
                        class: { "fixed-amount": _vm.fixedAmount },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.addToCart.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "price-wrapper" },
                          [
                            _vm.fixedAmount
                              ? [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "WebshopGiftCardsPriceLabel"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "price" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.product.price.formatted) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ])
                                ]
                              : [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "WebshopGiftCardsVariablePriceLabel"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "amount-wrapper" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.amount,
                                          expression: "amount",
                                          modifiers: { number: true }
                                        }
                                      ],
                                      attrs: {
                                        type: "number",
                                        min: "1",
                                        required: "",
                                        name: "amount",
                                        readonly: _vm.fixedAmount
                                      },
                                      domProps: { value: _vm.amount },
                                      on: {
                                        focus: _vm.selectValue,
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.amount = _vm._n(
                                            $event.target.value
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "currency" }, [
                                      _vm._v("DKK")
                                    ])
                                  ])
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("footer", { staticClass: "footer" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button-add-to-cart primary contained large",
                              attrs: { type: "submit" }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "WebshopGiftCardsAddToCart"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t"
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }