var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass: "table-component",
      attrs: { cellpadding: "0", cellspacing: "0", border: "0" }
    },
    _vm._l(_vm.address, function(item, $index) {
      return _c("tr", { key: $index }, [
        _c("td", { staticClass: "label" }, [
          _c("span", { attrs: { placeholder: "" } }, [
            _vm._v(_vm._s(_vm._f("translate")(item.dictionary)))
          ])
        ]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(item.value))])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }