var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { MiniCartMessage } from "webshop/models/cart/MiniCart";
import WebshopEvents from "webshop/utilities/WebshopEvents";
import { WebshopConfig } from "webshop/config";
import { EventTypes } from "webshop/models/EventTypes";
import Modal from '../utilities/Modal.vue';
import RelatedEvents from '../related/RelatedEvents.vue';
import RelatedEventItem from '../related/RelatedEventItem.vue';
let CartModal = class CartModal extends Vue {
    constructor() {
        super(...arguments);
        this.showModal = false;
        this.cartModal = null;
    }
    get cartPage() {
        return WebshopConfig.paths.cart;
    }
    relatedHeadline() {
        var translation = Vue.filter("translate")("WebshopMiniCartRelatedEvents");
        var date = Vue.filter("formatLongDate")(this.cartModal.date);
        return translation.replace("{date}", date);
    }
    closeMessage() {
        this.showModal = false;
        this.cartModal = null;
    }
    renderCartModalObject(cartModal) {
        this.showModal = true;
        this.cartModal = {
            message: cartModal && cartModal.message ? cartModal.message : new MiniCartMessage(Vue.filter("translate")("webshopGeneralCartProductAdded")),
            relatedEvents: cartModal && cartModal.relatedEvents ? cartModal.relatedEvents : null,
            date: cartModal && cartModal.date ? new Date(cartModal.date) : null
        };
        if (this.cartModal.relatedEvents) {
            this.relatedHeadline();
        }
    }
    created() {
        WebshopEvents.$on(EventTypes.CartMiniShowMessage, this.renderCartModalObject);
    }
    destroyed() {
        WebshopEvents.$off(EventTypes.CartMiniShowMessage);
    }
};
CartModal = __decorate([
    Component({
        components: {
            Modal,
            RelatedEvents,
            RelatedEventItem
        }
    })
], CartModal);
export default CartModal;
