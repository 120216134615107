import numeral from 'numeral';
import dayjs from 'dayjs';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import axios from 'axios';
import { AxiosHeaders } from "./config/Headers";
import Checkout from './pages/checkout/Checkout.vue';
import './components';
import './config/Dates';
let config;
// let instance: Vue;
let _window = window;
if (_window && _window.webshopConfig) {
    config = _window.webshopConfig;
    if (config) {
        // Set default culture for moment / numeral
        setCulture();
        // Set default site for API
        setAxiosHeaders();
        mapRouteComponents();
    }
}
export class Config {
}
export { config as WebshopConfig };
function mapRouteComponents() {
    let _configRoutes = config.configRoutes;
    let routes = [];
    if (_configRoutes) {
        _configRoutes.forEach(r => {
            let route = {};
            route.path = r.key;
            switch (r.value) {
                case "webshopCheckout":
                    route.component = Checkout;
                    route.children = Checkout.prototype.routes;
                    route.props = true;
                    break;
            }
            routes.push(route);
        });
    }
    config.routes = routes;
}
function setAxiosHeaders() {
    AxiosHeaders.headers = {
        "X-Culture": config.culture
    };
    axios.defaults.headers = AxiosHeaders.headers;
}
function setCulture() {
    // Numeral config
    let culture = config.culture;
    if (numeral.locales[culture] === undefined && config.cultureFormats) {
        numeral.register("locale", culture, {
            delimiters: {
                thousands: config.cultureFormats.numberFormat.groupSeparator,
                decimal: config.cultureFormats.numberFormat.decimalSeparator
            },
            abbreviations: {
                thousand: "k",
                million: "m",
                billion: "b",
                trillion: "t"
            },
            ordinal: function (number) {
                return number === 1 ? "er" : "ème";
            },
            currency: {
                symbol: "DKK"
            }
        });
        numeral.locale(culture);
    }
    // Set locale
    dayjs.locale(culture);
}
