var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import "webshop/filters/translate";
import WebshopEvents, { WebshopEventTypes } from "webshop/utilities/WebshopEvents";
export class Step {
    constructor(index, alias) {
        this.index = index;
        this.alias = alias;
    }
}
let CheckoutProgess = class CheckoutProgess extends Vue {
    constructor() {
        super(...arguments);
        this.cart = null;
        this.steps = [];
    }
    get stepsFiltered() {
        let _steps = [];
        let stepsArr = [];
        if (this.cart) {
            _steps.push('cart');
            _steps.push('information');
            // Vis kun delivery step hvis det er påkrævet af ordren
            if (this.cart?.shippingRequired) {
                _steps.push('delivery');
            }
            _steps.push('payment');
        }
        for (let i = 0; i < _steps.length; i++) {
            stepsArr.push(new Step(i + 1, _steps[i]));
        }
        return stepsArr;
    }
    mounted() {
        // let _steps:string[] = [];
        // _steps.push('cart');
        // _steps.push('information');
        // _steps.push('delivery'); // If enabled
        // _steps.push('payment');
        // for (let i=0; i<_steps.length; i++) {
        //   this.steps.push(new Step(i+1, _steps[i]));
        // }
        WebshopEvents.$on(WebshopEventTypes.CheckoutUpdateCart, this.updateCart);
    }
    updateCart(cart) {
        this.cart = cart;
    }
    getDictionaryKey(alias) {
        if (alias == "/") {
            return "webshopCheckoutCart";
        }
        else {
            return `webshopCheckout${alias.charAt(0).toUpperCase()}${alias.slice(1)}`;
        }
    }
};
CheckoutProgess = __decorate([
    Component({
        props: {
            step: Number
        },
    })
], CheckoutProgess);
export default CheckoutProgess;
