var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cart-summary" }, [
      _vm.showProducts
        ? _c(
            "ul",
            { staticClass: "products" },
            _vm._l(_vm.cart.lines, function(item, index) {
              return _c("li", { key: index }, [
                _c("span", { staticClass: "key" }, [
                  _c("span", { staticClass: "quantity" }, [
                    _vm._v(_vm._s(item.quantity) + "x")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "product-id" },
                    [
                      item.data && item.data.startDate
                        ? [
                            _c(
                              "p",
                              { staticClass: "date" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshopCheckoutCartDate"
                                      )
                                    ) +
                                    "\n                "
                                ),
                                item.data.startTime
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateAndHour")(
                                            item.data.startDate
                                          )
                                        )
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            item.data.startDate
                                          )
                                        )
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        : [_vm._v(_vm._s(item.productId))]
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(item.baseAmount))
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "total" },
        [
          _vm.cart.subtotalAmount
            ? _c("li", [
                _c("span", { staticClass: "key" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("WebshopCheckoutCartSubtotal")) +
                      ":"
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.cart.subtotalAmount))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cart.discountAmount
            ? _c("li", { staticClass: "discount" }, [
                _c("span", { staticClass: "key" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")("WebshopCheckoutCartDiscountTotal")
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.cart.discountAmount))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cart.shippingRequired
            ? [
                _c("li", [
                  _c("span", { staticClass: "key" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("WebshopCheckoutCartShippingTotal")
                      ) + ":"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.cart.shippingAmount
                    ? _c("span", { staticClass: "price" }, [
                        _vm._v(_vm._s(_vm.cart.shippingAmount))
                      ])
                    : _c("span", { staticClass: "price" }, [
                        _c("i", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "WebshopCheckoutCartShippingNotSelected"
                              )
                            )
                          )
                        ])
                      ])
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "summary-split" }),
          _vm._v(" "),
          _vm.cart.totalAmount
            ? _c("li", [
                _c("span", { staticClass: "key" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("WebshopCheckoutCartTotal")) +
                      ":"
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.cart.totalAmount))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cart.totalVATAmount
            ? _c("li", [
                _c("span", { staticClass: "key" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("WebshopCheckoutCartVAT")) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.cart.totalVATAmount))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cart.giftCard
            ? [
                _c("li", { staticClass: "summary-split" }),
                _vm._v(" "),
                _c("li", [
                  _c("span", { staticClass: "key" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("WebshopCheckoutCartGiftCardTotal")
                      ) + ":"
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.cart.giftCard.amount))
                  ])
                ]),
                _vm._v(" "),
                _vm.cart.totalAmount !== _vm.cart.dueAmount &&
                _vm.cart.dueAmount
                  ? _c("li", [
                      _c("span", { staticClass: "key" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")("WebshopCheckoutCartDueTotal")
                          ) + ":"
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price" }, [
                        _vm._v(_vm._s(_vm.cart.dueAmount))
                      ])
                    ])
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }