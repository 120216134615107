var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
export var LoaderSize;
(function (LoaderSize) {
    LoaderSize["Small"] = "small";
    LoaderSize["Large"] = "large";
})(LoaderSize || (LoaderSize = {}));
export var LoaderTheme;
(function (LoaderTheme) {
    LoaderTheme["Dark"] = "dark";
    LoaderTheme["Light"] = "light";
})(LoaderTheme || (LoaderTheme = {}));
let Loader = class Loader extends Vue {
    get themeClass() {
        return `loader--${this.theme}`;
    }
    get sizeClass() {
        return this.size ? this.size : LoaderSize.Small;
    }
};
Loader = __decorate([
    Component({
        props: {
            size: String,
            theme: {
                type: String,
                default: () => {
                    return LoaderTheme.Dark;
                }
            }
        }
    })
], Loader);
export default Loader;
