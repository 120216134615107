/*
    Load components for login and reset password:
        -   LoginFom
        -   RequestResetPassword
        -   ResetPassword
*/
import Vue from 'vue';
const LoginForm = () => ({
    component: import(/* webpackChunkName: 'login-form' */ './LoginForm.vue')
});
Vue.component('login-form', LoginForm);
const RequestResetPassword = () => ({
    component: import(/* webpackChunkName: 'reset-password' */ './RequestResetPassword.vue')
});
Vue.component('request-reset-password', RequestResetPassword);
const ResetPassword = () => ({
    component: import(/* webpackChunkName: 'reset-password' */ './ResetPassword.vue')
});
Vue.component('reset-password', ResetPassword);
const LogOut = () => ({
    component: import(/* webpackChunkName: 'log-out' */ './LogOut.vue')
});
Vue.component('log-out', LogOut);
