var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-form" },
    [
      !_vm.success
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                }
              }
            },
            [
              _c("label", { staticClass: "field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.name,
                      expression: "contact.name"
                    }
                  ],
                  attrs: {
                    type: "text",
                    name: "name",
                    required: "",
                    placeholder: " "
                  },
                  domProps: { value: _vm.contact.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.contact, "name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { attrs: { placeholder: "" } }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("formsNamePlaceholder")) + "*"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.email,
                      expression: "contact.email"
                    }
                  ],
                  attrs: {
                    type: "email",
                    name: "email",
                    required: "",
                    placeholder: " "
                  },
                  domProps: { value: _vm.contact.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.contact, "email", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { attrs: { placeholder: "" } }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("formsEmailPlaceholder")) + "*"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.phone,
                      expression: "contact.phone"
                    }
                  ],
                  attrs: {
                    type: "tel",
                    name: "phone",
                    required: "",
                    placeholder: " "
                  },
                  domProps: { value: _vm.contact.phone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.contact, "phone", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { attrs: { placeholder: "" } }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("formsPhonePlaceholder")) + "*"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.company,
                      expression: "contact.company"
                    }
                  ],
                  attrs: {
                    type: "text",
                    name: "company",
                    required: "",
                    placeholder: " "
                  },
                  domProps: { value: _vm.contact.company },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.contact, "company", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { attrs: { placeholder: "" } }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("formsCompanyPlaceholder")) + "*"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("label", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.message,
                      expression: "contact.message"
                    }
                  ],
                  attrs: {
                    name: "message",
                    placeholder: _vm._f("translate")("formsMessagePlaceholder"),
                    required: ""
                  },
                  domProps: { value: _vm.contact.message },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.contact, "message", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.privacyPolicy
                ? [
                    _c("label", { staticClass: "checkbox" }, [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          name: "acceptTermsOfPrivacy",
                          required: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "description" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("translate")("formsAcceptPrivacyPolicy")
                            ) +
                            "\n                    "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: _vm.privacyPolicy, target: "_blank" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("formsReadPrivacyPolicy")
                              )
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading"
                    }
                  ],
                  staticClass: "button button-full",
                  attrs: { type: "submit" }
                },
                [_vm._v(_vm._s(_vm._f("translate")("formsSubmit")))]
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      }),
      _vm._v(" "),
      _vm.success
        ? _c("div", [
            _c("div", {
              staticClass: "message",
              domProps: { innerHTML: _vm._s(_vm.successMessage) }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.submitted && !_vm.success && !_vm.loading
        ? _c("div", [
            _c("div", {
              staticClass: "message",
              domProps: { innerHTML: _vm._s(_vm.errorMessage) }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }