export class Ticket {
}
export class TicketVM {
    constructor(ticket, quantity, time) {
        this.ticket = ticket;
        this.quantity = quantity;
        this.time = time;
    }
}
export var TicketType;
(function (TicketType) {
    TicketType["Event"] = "Event";
    TicketType["Entrance"] = "Entrance";
})(TicketType || (TicketType = {}));
// export interface ITicketEventTimes {
//     productId: string;
//     accessTimeOption: string;
//     calculated: boolean;
//     createdDateUTC: string;
//     description: string;
//     periodCode: string;
//     salesEndDate: string;
//     saelsStartDate: string;
//     startDate: string;
// }
