import { Service } from "@jcd-web/basic-service";
import { ProductApi } from "../api/ProductApi";
export class SearchObject {
    constructor(query, page, pageSize, categoryId, productTypeId, sortBy, sortDirection) {
        this.query = query ? query : "";
        this.page = page ? page : 1;
        this.pageSize = pageSize ? pageSize : 25;
        this.categoryId = categoryId ? categoryId : null;
        this.productTypeId = productTypeId ? productTypeId : "";
        this.sortBy = sortBy ? sortBy : "";
        this.sortDirection = sortDirection ? sortDirection : "";
    }
}
export class ProductQueryObject {
    constructor(c, page, orderBy) {
        this.c = c ? c : null;
        this.page = page ? page : 1;
        this.orderBy = orderBy;
    }
}
export class SortOption {
    constructor(alias, dictionary) {
        this.alias = alias;
        this.dictionary = dictionary;
    }
}
export var ProductTypes;
(function (ProductTypes) {
    ProductTypes["Ticket"] = "Ticket";
    ProductTypes["GiftCard"] = "GiftCard";
    ProductTypes["AdmissionCard"] = "AdmissionCard";
})(ProductTypes || (ProductTypes = {}));
export class ProductService extends Service {
    static getProducts(search) {
        return new Promise((resolve, reject) => {
            ProductApi.getProducts(search).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // static getByCategoryId(categoryId: number | null, page: number, pageSize: number) {
    //   return new Promise<IResultSet<IProduct>>((resolve, reject) => {
    //     ProductApi.getByCategoryId(categoryId, page, pageSize).then(res => {
    //       resolve(res.data as IResultSet<IProduct>);
    //     }).catch(err => {
    //       reject(err);
    //     });
    //   })
    // }
    // static getAll(returnType: string) {
    //   return new Promise<IResultSet<IProduct>>((resolve, reject) => {
    //     ProductApi.getAll(returnType).then(res => {
    //       resolve(res.data as IResultSet<IProduct>);
    //     }).catch(err => {
    //       reject(err);
    //     });
    //   })
    // }
    // static getByIds(productIds: string[]) {
    //   return new Promise<IProduct[]>((resolve, reject) => {
    //     ProductApi.getByIds(productIds).then(res => {
    //       resolve(res.data as IProduct[]);
    //     }).catch(err => {
    //       reject(err);
    //     });
    //   })
    // }
    static get(id) {
        return new Promise((resolve, reject) => {
            ProductApi.get(id).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static getSortOptions() {
        return [
            new SortOption('name', 'webshopSortOptionName'),
            new SortOption('nameDescending', 'webshopSortOptionNameDescending'),
            new SortOption('price', 'webshopSortOptionPrice'),
            new SortOption('priceDescending', 'webshopSortOptionPriceDescending'),
            new SortOption('newest', 'webshopSortOptionNewest'),
            new SortOption('oldest', 'webshopSortOptionOldest'),
        ];
    }
}
