import Vue from 'vue';
// Set localization
Vue.filter('translate', (key, args) => {
    const _window = window;
    const dictionary = _window && _window.dictionary ? _window.dictionary : null;
    // Lowercase for at match camel-case i dictionary
    key = key[0].toLowerCase() + key.substring(1);
    if (dictionary && dictionary[key] !== undefined) {
        let item = dictionary[key];
        // Lav replacement af args i strengen
        const type = typeof (args);
        if (type && type !== 'undefined') {
            if (type === 'string' || type === 'number') {
                item = item.replace('${0}', args);
            }
            else {
                for (let i = 0; i < args.length; i++) {
                    let _arg = args[i];
                    item = item.replace('${' + i + '}', _arg);
                }
            }
        }
        return item;
    }
    return key;
});
