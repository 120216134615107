var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "@jcd-web/basic-toast";
import CartService from '../../services/CartService';
import { ErrorService } from '../../services/ErrorService';
import { GiftCard } from "../../models/giftcard/GiftCard";
import Loader from '../utilities/Loader.vue';
import Message from '../utilities/Message.vue';
/*
    Test: 1000171
*/
let RedeemGiftCard = class RedeemGiftCard extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.show = false;
        this.success = false;
        this.redeemGiftCard = new GiftCard();
        this.newGiftCardMode = false;
        this.giftCardResponse = null;
    }
    mounted() {
        this.loading = false;
    }
    get active() {
        if ((this.giftCard && Object.keys(this.giftCard).length > 0 && !this.newGiftCardMode) || this.success) {
            return true;
        }
        return false;
    }
    toggleForm() {
        this.show = true;
        this.$nextTick(() => {
            let _elm = this.$refs?.giftCardInput;
            if (_elm) {
                _elm.focus();
            }
        });
    }
    removeGiftCard() {
        this.loading = true;
        CartService.removeGiftCard().then(res => {
            // this.loading = false;
            this.$emit('update');
            ToastService.success(Vue.filter("translate")("WebshopCheckoutGiftCardRemoved"), 4000);
            if (this.giftCardResponse) {
                this.giftCardResponse = null;
                this.redeemGiftCard.id = "";
                this.loading = false;
                this.show = false;
            }
            else {
                // Lav lidt delay på loader, hvis det er et eksisterende gavekort på kurven
                setTimeout(() => {
                    this.loading = false;
                    this.show = false;
                }, 1000);
            }
        }, err => {
            this.loading = false;
            ErrorService.handleError(err);
        });
    }
    addGiftCard() {
        this.loading = true;
        CartService.addGiftCard(this.redeemGiftCard).then(res => {
            ToastService.success(Vue.filter("translate")("WebshopCheckoutGiftCardAdded"), 4000);
            this.giftCardResponse = res;
            this.newGiftCardMode = true;
            setTimeout(() => {
                this.loading = false;
            }, 1000);
            this.$emit('update');
        }, err => {
            ErrorService.handleError(err);
            this.loading = false;
        });
    }
};
RedeemGiftCard = __decorate([
    Component({
        props: {
            used: {
                type: Boolean,
                default: false
            },
            giftCard: {
                type: Object,
                default: () => {
                    return new GiftCard();
                }
            }
        },
        components: {
            Loader,
            Message
        }
    })
], RedeemGiftCard);
export default RedeemGiftCard;
