var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticket-slots" },
    [
      _c(
        "header",
        {
          staticClass: "ticket-configurator-header",
          class: {
            active: _vm.selectedEventTime == null && _vm.selectedDate != null,
            passed: _vm.selectedEventTime != null,
            disabled: _vm.disabled
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleSelectedEventTime.apply(null, arguments)
            }
          }
        },
        [
          _c("span", { staticClass: "mdi header-icon mdi-clock-outline" }),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "title" }, [
              _vm.label
                ? _c("span", [
                    _vm._v("\n          " + _vm._s(_vm.label) + "\n          ")
                  ])
                : _c("span", [
                    _vm._v(
                      "\n            2. " +
                        _vm._s(_vm._f("translate")("webshopTicketChooseTime")) +
                        "\n          "
                    )
                  ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "selected-value" }, [
              _vm.selectedEventTime
                ? _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.selectedEventTime.start) +
                        " - " +
                        _vm._s(_vm.selectedEventTime.end) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.eventTimes && !_vm.disabled
        ? [
            _c(
              "div",
              {
                staticClass: "ticket-event-items",
                class: { hidden: _vm.selectedEventTime }
              },
              _vm._l(_vm.eventTimes, function(item, $index) {
                return _c("ticket-event-item", {
                  key: $index,
                  attrs: { item: item },
                  on: { select: _vm.onSelectEventTime }
                })
              }),
              1
            )
          ]
        : void 0
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }