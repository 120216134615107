import { render, staticRenderFns } from "./RedeemPromotionCode.vue?vue&type=template&id=b6f08174&"
import script from "./RedeemPromotionCode.vue?vue&type=script&lang=ts&"
export * from "./RedeemPromotionCode.vue?vue&type=script&lang=ts&"
import style0 from "./RedeemPromotionCode.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Buildserver\\Agent01\\_work\\316\\s\\JCD.Retail.Webshop\\App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6f08174')) {
      api.createRecord('b6f08174', component.options)
    } else {
      api.reload('b6f08174', component.options)
    }
    module.hot.accept("./RedeemPromotionCode.vue?vue&type=template&id=b6f08174&", function () {
      api.rerender('b6f08174', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/webshop/components/promotion-codes/RedeemPromotionCode.vue"
export default component.exports