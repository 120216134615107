import { Service } from "@jcd-web/basic-service";
import { UserApi } from "../api/UserApi";
export class UserService extends Service {
    static create(model) {
        return new Promise((resolve, reject) => {
            UserApi.create(model).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static get() {
        return new Promise((resolve, reject) => {
            UserApi.get().then(res => {
                var user = res.data;
                if (!user.countryCode)
                    user.countryCode = null;
                resolve(user);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static update(model) {
        return new Promise((resolve, reject) => {
            UserApi.update(model).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static changePassword(changePassword) {
        return new Promise((resolve, reject) => {
            UserApi.changePassword(changePassword).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response);
            });
        });
    }
    static updatePreferences(model) {
        return new Promise((resolve, reject) => {
            UserApi.updatePreferences(model).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response);
            });
        });
    }
    static login(login) {
        return new Promise((resolve, reject) => {
            UserApi.login(login).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err.response);
            });
        });
    }
    static logout() {
        return new Promise((resolve, reject) => {
            UserApi.logout().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static activate(guid, model) {
        return new Promise((resolve, reject) => {
            UserApi.activate(guid, model).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static resetPassword(guid, model) {
        return new Promise((resolve, reject) => {
            UserApi.resetPassword(guid, model).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static requestResetPassword(email) {
        let model = {
            email: email
        };
        return new Promise((resolve, reject) => {
            UserApi.requestResetPassword(model).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    // Admission cards
    static getMyAdmissionCards() {
        return new Promise((resolve, reject) => {
            UserApi.getMyAdmissionCards().then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
    static renewAdmissionCard(cardId) {
        return new Promise((resolve, reject) => {
            UserApi.renewAdmissionCard(cardId).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }
}
