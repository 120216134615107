/*
    Registrer de mest gængse komponenter globalt, så de ikke skal inkluderes
    specifikt i hvert enkelt komponent de skal bruges i.
*/
import Vue from 'vue';
import './components/user/components';
import './components/products/components';
import './components/login/components';
import GiftCard from 'webshop/components/giftcard/GiftCard.vue';
Vue.component('gift-card', GiftCard);
import CartMini from "webshop/components/cart/CartMini.vue";
Vue.component('cart-mini', CartMini);
// Navigation
import MobileNavigation from "webshop/components/navigation/MobileNavigation.vue";
Vue.component('mobile-navigation', MobileNavigation);
import CartModal from "webshop/components/cart/CartModal.vue";
Vue.component('cart-modal', CartModal);
// Layout components
import Card from "webshop/components/utilities/card/Card.vue";
Vue.component('card', Card);
import Modal from "webshop/components/utilities/Modal.vue";
Vue.component('modal', Modal);
import Loader from "webshop/components/utilities/Loader.vue";
Vue.component('loader', Loader);
import Message from "webshop/components/utilities/Message.vue";
Vue.component('message', Message);
import TicketDownload from "webshop/components/tickets/TicketDownload.vue";
Vue.component('ticket-download', TicketDownload);
import UserMiniMe from "webshop/components/user/UserMiniMe.vue";
Vue.component('user-mini-me', UserMiniMe);
import TicketConfigurator from "webshop/components/tickets/TicketConfigurator.vue";
Vue.component('ticket-configurator', TicketConfigurator);
import DrawerTicketConfigurator from "webshop/components/tickets/DrawerTicketConfigurator.vue";
Vue.component('drawer-ticket-configurator', DrawerTicketConfigurator);
import TicketDrawerButton from "webshop/components/tickets/TicketDrawerButton.vue";
Vue.component('ticket-drawer-button', TicketDrawerButton);
import Checkout from "webshop/pages/checkout/Checkout.vue";
Vue.component('checkout', Checkout);
import Receipt from "webshop/pages/Reciept.vue";
Vue.component('receipt', Receipt);
import RenderImage from 'webshop/components/utilities/RenderImage.vue';
Vue.component('render-image', RenderImage);
import Icon from 'webshop/components/utilities/icon/Icon.vue';
Vue.component('icon', Icon);
// Lazy components
const AdmissionCards = () => ({
    component: import(/* webpackChunkName: 'AdmissionCards' */ 'webshop/components/admission-card/AdmissionCards.vue')
});
Vue.component('admission-cards', AdmissionCards);
const InlineTicketConfigurator = () => ({
    component: import(/* webpackChunkName: 'InlineTicketConfigurator' */ 'webshop/components/tickets/InlineTicketConfigurator.vue')
});
Vue.component('inline-ticket-configurator', InlineTicketConfigurator);
