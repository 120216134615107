export class Donation {
    constructor(amount, firstName, lastName, address, zipCode, city, email, cpr, cvr, acceptMarketing) {
        this.amount = amount;
        this.firstName = firstName;
        this.lastName = lastName;
        this.address = address;
        this.zipCode = zipCode;
        this.city = city;
        this.email = email;
        this.cpr = cpr;
        this.cvr = cvr;
        this.acceptMarketing = acceptMarketing;
    }
}
