var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "@jcd-web/basic-toast";
import CartService from "../../services/CartService";
import { ErrorService } from "../../services/ErrorService";
import WebshopEvents, { WebshopEventTypes } from "../../utilities/WebshopEvents";
import { EventTypes } from "../../models/EventTypes";
import { PromotionCode } from "../../models/promotion-codes/PromotionCodes";
import Loader from "../utilities/Loader.vue";
let RedeemPromotionCode = class RedeemPromotionCode extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.showPromotionCodeField = false;
    }
    mounted() {
        this.loading = false;
    }
    get promotionCode() {
        if (this.currentPromotionCode) {
            this.showPromotionCodeField = true;
            return this.currentPromotionCode;
        }
        return new PromotionCode();
    }
    get hasActivePromotionCode() {
        return this.currentPromotionCode != null;
    }
    focus() {
        this.$nextTick(() => {
            let inputField = this.$refs?.promotionCodeInput;
            if (inputField) {
                inputField.focus();
            }
        });
    }
    removePromotionCode() {
        this.loading = true;
        CartService.removePromotionCode().then(() => {
            WebshopEvents.$emit(EventTypes.UpdateCart);
            ToastService.success(Vue.filter("translate")("WebshopCheckoutPromotionCodeRemoved"), 4000);
            WebshopEvents.$emit(WebshopEventTypes.CheckoutPromotionCodeUpdate);
            this.loading = false;
            this.showPromotionCodeField = false;
        }, (err) => {
            this.loading = false;
            ErrorService.handleError(err);
        });
    }
    validatePromotionCode() {
        this.loading = true;
        CartService.addPromotionCode(this.promotionCode).then(() => {
            ToastService.success(Vue.filter("translate")("WebshopCheckoutPromotionCodeAdded"), 4000);
            this.loading = false;
            WebshopEvents.$emit(WebshopEventTypes.CheckoutPromotionCodeUpdate);
        }, (err) => {
            ErrorService.handleError(err);
            this.loading = false;
        });
    }
};
RedeemPromotionCode = __decorate([
    Component({
        props: {
            currentPromotionCode: Object,
            allowPromotionCode: Boolean
        },
        components: {
            Loader
        }
    })
], RedeemPromotionCode);
export default RedeemPromotionCode;
