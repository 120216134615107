export class AdmissionCardVM {
}
// export class AdmissionCardFormVM {
//     title: string;
//     description: string;
//     renewalMessage: string;
//     createMessage: string;
//     footerMessage: string;
// }
export class AdmissionCardFormVM {
    constructor(definition) {
        this.definition = definition;
    }
}
export class AdmissionCardFormContentVM {
}
export class AdmissionCardDetails {
}
export class AdmissionCardRenewalVM {
    constructor(cardId, productId, definition, details, checksum, renewable, startDate) {
        this.cardId = cardId;
        this.productId = productId;
        this.definition = definition;
        this.details = details;
        this.checksum = checksum;
        this.renewable = renewable;
        this.startDate = startDate;
    }
}
export var CustomerType;
(function (CustomerType) {
    CustomerType["Consumer"] = "Consumer";
    CustomerType["Business"] = "Business";
})(CustomerType || (CustomerType = {}));
export var AdmissionDefinitionType;
(function (AdmissionDefinitionType) {
    AdmissionDefinitionType["Predefined"] = "Predefined";
    AdmissionDefinitionType["Calculation"] = "Calculation";
})(AdmissionDefinitionType || (AdmissionDefinitionType = {}));
export var AdmissionFormState;
(function (AdmissionFormState) {
    AdmissionFormState["Passed"] = "passed";
    AdmissionFormState["Active"] = "active";
    AdmissionFormState["Default"] = "default";
})(AdmissionFormState || (AdmissionFormState = {}));
