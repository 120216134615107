var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    { staticClass: "checkout-admission-card card-component--border" },
    [
      _c("header", { staticClass: "checkout-admission-card__header" }, [
        _vm.owner
          ? _c("div", { staticClass: "checkout-admission-card__content" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.owner.firstName) + " " + _vm._s(_vm.owner.lastName)
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              !_vm.showDetails
                ? _c(
                    "a",
                    {
                      staticClass: "card-read-more-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleDetails.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("translate")("WebshopGeneralShow")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "checkout-admission-card__actions" }, [
          _vm.statusText
            ? _c(
                "span",
                {
                  staticClass:
                    "card-tag card-tag--secondary checkout-admission-card__action"
                },
                [_vm._v("\n        " + _vm._s(_vm.statusText) + "\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "checkout-admission-card__action",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.remove()
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("translate")("WebshopCheckoutAdmissionCardRemove")
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.owner && _vm.showDetails
        ? _c("div", { staticClass: "checkout-admission-card__details" }, [
            _c(
              "div",
              { staticClass: "checkout-admission-card__fields" },
              _vm._l(_vm.ownerDetails, function(item) {
                return _c(
                  "div",
                  { key: item.key, staticClass: "card-component__field" },
                  [
                    _c("span", { staticClass: "card-component__field-label" }, [
                      _vm._v(_vm._s(_vm._f("translate")(item.alias)))
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "card-component__field-value" },
                      [
                        item.key == "birthDate"
                          ? [
                              _vm._v(
                                _vm._s(_vm._f("formatLongDate")(item.value))
                              )
                            ]
                          : item.key == "gender"
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "WebshopAdmissionCardOwnersGender" +
                                      item.value
                                  )
                                )
                              )
                            ]
                          : [_vm._v(_vm._s(item.value))]
                      ],
                      2
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "card-read-more-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleDetails.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("translate")("WebshopGeneralHide")) +
                    "\n    "
                )
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }