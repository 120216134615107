var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkout", attrs: { container: "" } }, [
    _c("div", { staticClass: "grid" }, [
      _c(
        "div",
        { attrs: { columns: "12" } },
        [
          _vm.showProgress
            ? _c("checkout-progress", { attrs: { step: _vm.step } })
            : _vm._e(),
          _vm._v(" "),
          _c("router-view", { attrs: { "current-user": _vm.currentUser } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }