var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "icon-component" }, [
    _c("img", {
      attrs: {
        src: "/app/dist/assets/webshop/icons/" + _vm.name + ".svg",
        width: _vm.size,
        height: _vm.size,
        alt: _vm.altText,
        loading: "lazy"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }