var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "event-time-component" }, [
    _vm.showDate && _vm.dateDisplay
      ? _c("time", [
          _c("i", { staticClass: "mdi mdi-calendar" }),
          _vm._v(" "),
          _c("span", { staticClass: "time-content" }, [
            _vm._v("\n      " + _vm._s(_vm.dateDisplay) + "\n    ")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.timeDisplay
      ? _c("time", [
          _c("i", { staticClass: "mdi mdi-clock-outline" }),
          _vm._v(" "),
          _c("span", { staticClass: "time-content" }, [
            _vm._v("\n      " + _vm._s(_vm.timeDisplay) + "\n    ")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }