var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CartService from '../../services/CartService';
import { ProductService } from '../../services/ProductService';
import { ErrorService } from '../../services/ErrorService';
import WebshopEvents from '../../utilities/WebshopEvents';
import { EventTypes } from '../../models/EventTypes';
import Loader from '../utilities/Loader.vue';
import Message from '../utilities/Message.vue';
import { CartLineInsert } from "../../models/cart/Cart";
import '../../filters/translate';
import { WebshopConfig } from "../../config";
import { MiniCartMessage, MiniCartMessageLine } from "webshop/models/cart/MiniCart";
import { ICartModal } from "webshop/models/cart/CartModal";
let GiftCard = class GiftCard extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.amount = 0;
        this.product = null;
    }
    get fixedAmount() {
        if (this.product?.price?.value) {
            return this.product.price.value > 0;
        }
        return false;
    }
    get giftCardEnabled() {
        return WebshopConfig.ecommerce.giftCardPaymentEnabled;
    }
    mounted() {
        this.init();
    }
    init() {
        const id = this.id;
        this.loading = false;
        if (id) {
            ProductService.get(id).then(product => {
                this.product = product;
            });
        }
    }
    addToCart() {
        let cartLines = [];
        let data = {};
        this.loading = true;
        if (!this.fixedAmount) {
            data.amount = this.amount;
        }
        let _cartLine = new CartLineInsert(this.id, 1, data);
        // Tracking data
        if (this.product) {
            _cartLine.name = this.product.name;
            _cartLine.price = this.fixedAmount ? this.product.price?.value : data.amount;
        }
        cartLines.push(_cartLine);
        CartService.createLine(cartLines).then((res) => {
            WebshopEvents.$emit(EventTypes.UpdateCartMini);
            let cartModalObj = new ICartModal();
            let _product = this.product;
            let _message = new MiniCartMessage(Vue.filter('translate')('WebshopGeneralCartProductAdded'));
            let _messageLine = new MiniCartMessageLine(_product.name, 1); // 1 ersattes med quantity
            _messageLine.icon = "wallet-giftcard";
            _message.lines = [
                _messageLine
            ];
            cartModalObj.message = _message;
            WebshopEvents.$emit(EventTypes.CartMiniShowMessage, cartModalObj);
            this.loading = false;
        }, (err) => {
            this.loading = false;
            ErrorService.handleError(err.response);
        });
    }
    selectValue(e) {
        if (e && e.target) {
            e.target.select();
        }
    }
};
GiftCard = __decorate([
    Component({
        props: {
            id: String
        },
        components: {
            Loader,
            Message
        }
    })
], GiftCard);
export default GiftCard;
