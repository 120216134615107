var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ToastList, { ToastService } from "@jcd-web/basic-toast";
import VideoBackground from "@jcd-web/basic-video-background";
import LanguageSelector from "components/navigation/LanguageSelector.vue";
import AppHeader from "components/Header.vue";
import CallToAction from "components/CallToAction.vue";
import ContactForm from "components/forms/ContactForm.vue";
//import ContactFormSponsor from "components/forms/ContactFormSponsor.vue";
import CookiebotDeclaration from "components/CookiebotDeclaration.vue";
import GalleryWithTextAndImage from "components/grid/galleryWithTextAndImage/GalleryWithTextAndImage.vue";
import ContentSlider from "components/grid/contentSlider.vue";
import EventList from "components/events/EventList.vue";
import MaterialChapters from "components/education/material/chapters.vue";
import Materials from "components/education/material/materials.vue";
import { State } from "config/State";
// Webshop
import WebshopEvents from '../webshop/utilities/WebshopEvents';
import { WebshopConfig } from "../webshop/config";
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.languageSelectorVisible = false;
        this.showLogin = false;
    }
    get props() {
        let _props = {};
        if (this.$attrs.types) {
            _props.types = this.$attrs.types;
        }
        return _props;
    }
    created() {
        State.language = State.language || this.language;
        State.currentId = State.currentId || this.currentId;
        State.culture = State.culture || this.culture;
        State.rootId = State.rootId || this.rootId;
        State.shopEnabled = this.shopEnabled;
    }
    mounted() {
        // Flyttes til sit eget komponent / PJH
        WebshopEvents.$off("handleError"); // Sikrer der ikke er dobbelt registrering af events ift. ekstra "App" med vue router
        WebshopEvents.$on("handleError", (data) => {
            this.handleError(data);
        });
    }
    handleError(errorMessage) {
        ToastService.danger(errorMessage, 2500);
    }
    toggleLanguageSelector() {
        if (this.languageSelectorVisible)
            this.languageSelectorVisible = false;
        else
            this.languageSelectorVisible = true;
    }
};
App = __decorate([
    Component({
        components: {
            ToastList,
            VideoBackground,
            LanguageSelector,
            CallToAction,
            CookiebotDeclaration,
            GalleryWithTextAndImage,
            ContactForm,
            // ContactFormSponsor,
            ContentSlider,
            EventList,
            MaterialChapters,
            AppHeader,
            Materials,
        },
        props: {
            rootId: Number,
            currentId: Number,
            language: String,
            rootName: String,
            settings: Object,
            searchQueryKey: String,
            searchQueryValue: String,
            searchPageUrl: String,
            shopEnabled: Boolean,
            siteId: Number,
        },
    })
], App);
export default App;
if (WebshopConfig && WebshopConfig.routes) {
    App.prototype.routes = WebshopConfig.routes;
}
