var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import UserMiniMe from '../webshop/components/user/UserMiniMe.vue';
import CartMini from "../webshop/components/cart/CartMini.vue";
let AppHeader = class AppHeader extends Vue {
    constructor() {
        super(...arguments);
        this.stickyHeader = false;
    }
    onScroll() {
        if (window.scrollY >= 90 && !this.stickyHeader) {
            this.stickyHeader = true;
        }
        else if (window.scrollY < 90 && this.stickyHeader) {
            // if (!this.stickyHeader) {
            this.stickyHeader = false;
            // }
        }
    }
    created() {
        window.addEventListener('scroll', this.onScroll);
    }
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    }
};
AppHeader = __decorate([
    Component({
        components: {
            CartMini,
            UserMiniMe
        }
    })
], AppHeader);
export default AppHeader;
