var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "message" } }, [
    _c(
      "div",
      {
        class: [
          "message-component",
          _vm.stateClass,
          _vm.sizeClass,
          { "has-icon": _vm.hasIcon }
        ]
      },
      [
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.icon
              ? _c("span", { staticClass: "icon-wrapper" }, [
                  _c("i", { class: _vm.iconClass })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }