var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CartService from "webshop/services/CartService";
import WebshopEvents from "webshop/utilities/WebshopEvents";
import { ErrorService } from "webshop/services/ErrorService";
import { WebshopConfig } from "webshop/config";
let CartMini = class CartMini extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.cart = null;
        this.config = WebshopConfig;
    }
    get cartPage() {
        return this.config.paths.cart;
    }
    get label() {
        return this.config.cart.label;
    }
    get labelActive() {
        return this.config.cart.labelActive;
    }
    loadCart() {
        CartService.getMiniCart().then(res => {
            this.cart = res;
        }).catch(err => {
            ErrorService.handleError(err.response);
        });
    }
    mounted() {
        this.loadCart();
    }
    created() {
        WebshopEvents.$on('update-cart-mini', () => {
            this.loadCart();
        });
    }
};
CartMini = __decorate([
    Component({})
], CartMini);
export default CartMini;
