var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { TicketVM } from "../../models/ticket/Ticket";
import TicketListItem from './TicketListItem.vue';
import { Watch } from "vue-property-decorator";
import Message from '../utilities/Message.vue';
import Loader from '../utilities/Loader.vue';
let TicketList = class TicketList extends Vue {
    constructor() {
        super(...arguments);
        this.currentCapacity = null;
        this.selectedTickets = [];
    }
    onTicketsChanged(value, oldValue) {
        /*
          Nulstil alle valg når "tickets" bliver ændret. F.eks. ved valg af en anden eventTime
        */
        if (value) {
            this.currentCapacity = null;
            this.selectedTickets = [];
            this.$forceUpdate(); // Nulstiller TicketListItem
        }
    }
    get disableAdd() {
        // Event men ingen kapacitet
        if (this.selectedEventTime?.availableCapacity === 0) {
            return true;
        }
        // Ingen event
        else if (this.selectedEventTime == null || this.initialCapacity == null) {
            return false;
        }
        // Udregn kapacitet
        else {
            return this.currentCapacity !== null ? this.currentCapacity === 0 : false;
        }
    }
    get activeState() {
        if (this.type === 'Event' && this.selectedDate && this.selectedEventTime) {
            return true;
        }
        else if (this.type === 'Entrance' && this.selectedDate) {
            return true;
        }
        return false;
    }
    get formatTickets() {
        return this.tickets.map(x => new TicketVM(x, 0));
    }
    get initialCapacity() {
        return this.selectedEventTime && this.selectedEventTime.availableCapacity ? this.selectedEventTime.availableCapacity : null;
    }
    get capacityMessage() {
        let _availableCapacity = this.selectedEventTime && this.currentCapacity !== null ? this.currentCapacity : this.initialCapacity;
        return `${Vue.filter("translate")('webshopTicketTypeEventAvailableTickets', _availableCapacity)}`;
    }
    get isSoldOut() {
        return this.selectedEventTime && this.selectedEventTime.availableCapacity == 0;
    }
    updateCapacity() {
        if (this.selectedTickets) {
            let _quantity = 0;
            /*
              Tilføjer selectedEventTime til ticket objektet hvis den er valgt i TicketConfigurator
            */
            this.selectedTickets.map(x => {
                if (x.quantity)
                    _quantity += x.quantity;
                x.time = this.selectedEventTime;
            });
            this.currentCapacity = (this.initialCapacity - _quantity);
            this.$emit('update-selected-tickets', this.selectedTickets);
        }
        else if (this.selectedEventTime) {
            /*
              Nulstil capacity hvis der ikke er valgt nogle biletter
            */
            this.currentCapacity = Object.assign({}, this.selectedEventTime.availableCapacity);
        }
    }
    onUpdateListItem(item, quantity) {
        let _ticket = this.selectedTickets.find(x => x.ticket.id == item.ticket.id);
        if (!_ticket) {
            _ticket = item;
            _ticket.quantity = quantity;
            this.selectedTickets.push(_ticket);
        }
        else if (_ticket) {
            _ticket.quantity = quantity;
        }
        if (quantity == 0 && _ticket) {
            let _index = this.selectedTickets.indexOf(_ticket);
            if (_index > -1) {
                this.selectedTickets.splice(_index, 1);
            }
        }
        this.updateCapacity();
    }
    availableTickets() {
        if (this.selectedEventTime && this.selectedEventTime.availableCapacity && this.selectedEventTime.availableCapacity > 0) {
            return true;
        }
        return false;
    }
};
__decorate([
    Watch('tickets')
], TicketList.prototype, "onTicketsChanged", null);
TicketList = __decorate([
    Component({
        props: {
            type: String,
            tickets: Array,
            selectedDate: Date,
            selectedEventTime: Object,
            pickTicketsLabel: String,
            step: String,
            loading: Boolean
        },
        components: {
            TicketListItem,
            Message,
            Loader
        }
    })
], TicketList);
export default TicketList;
