import { Service } from "@jcd-web/basic-service";
/*
    TO-DO:
    Ift. parametre der bliver sat på hhv. purchase og addToCart, skal de udbygges jo mere data vi får tilgængeligt ift.
    webshoppens data på produkter osv.

*/
export class TrackingService extends Service {
    // GA4 
    static pushEcommerceEvent(type, data) {
        if (data) {
            let ecommerceData = data;
            if (type == AnalyticsEventType.Purchase) {
                ecommerceData = { [type]: data };
            }
            let _data = {
                'event': type,
                'ecommerce': ecommerceData
            };
            this.dataLayerPush(_data);
        }
    }
    static dataLayerPush(data) {
        if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push(data);
        }
    }
    // Universal Analytics
    static pushUAEcommerceEvent(type, data) {
        if (data) {
            let ecommerceData = data;
            if (type == AnalyticsEventType.UAPurchase) {
                ecommerceData = { [type]: data };
            }
            let _data = {
                'event': 'eec.' + type,
                'ecommerce': ecommerceData
            };
            this.dataLayerPush(_data);
        }
    }
    static purchase(order) {
        // Set data obj
        // GA 4
        let data = new GAPurchase(order.id.toString());
        data.currency = order.currencyCode;
        data.value = order.totalAmount.value;
        data.tax = order.totalVATAmount?.value;
        // Items
        let items = [];
        if (order.lines) {
            order.lines.forEach(x => {
                let _item = new GAPurchaseItem(x.productId);
                _item.quantity = x.quantity;
                _item.item_name = x.productName;
                _item.currency = x.currencyCode;
                _item.price = x.price?.value;
                items.push(_item);
            });
        }
        data.items = items;
        this.pushEcommerceEvent(AnalyticsEventType.Purchase, data);
        // UA data
        let UAData = {};
        let UAProducts = [];
        UAData.actionField = {
            'id': data.transaction_id,
            'revenue': data.value,
            'tax': data.tax,
            'shipping': data.shipping,
            'coupon': data.coupon,
            'affiliation': data.affiliation
        };
        if (order.lines) {
            order.lines.forEach(x => {
                UAProducts.push({
                    'name': x.productName,
                    'id': x.productId,
                    'price': x.price?.value,
                    'quantity': x.quantity
                });
            });
            UAData.products = UAProducts;
        }
        console.log(UAData);
        this.pushUAEcommerceEvent(AnalyticsEventType.UAPurchase, UAData);
    }
    static addToCart(data) {
        // G4 data
        let GAData = new GAAddToCart();
        let items = [];
        if (data.lines) {
            data.lines.forEach(x => {
                let _line = new GAAddToCartItem(x.productId);
                _line.quantity = x.quantity;
                _line.price = x.price;
                _line.item_name = x.productName;
                items.push(_line);
            });
        }
        GAData.items = items;
        GAData.currency = data.currencyCode;
        GAData.value = data.totalAmount;
        this.pushEcommerceEvent(AnalyticsEventType.AddToCart, GAData);
        // UA data
        let UAData = {};
        let UAProducts = [];
        if (data.lines) {
            data.lines.forEach(x => {
                UAProducts.push({
                    'name': x.productName,
                    'id': x.productId,
                    'price': x.price,
                    'quantity': x.quantity
                });
            });
        }
        UAData.add = {
            products: UAProducts
        };
        UAData.currencyCode = data.currencyCode;
        this.pushUAEcommerceEvent(AnalyticsEventType.UAAddToCart, UAData);
    }
}
// Ecommerce 
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
/*
    Index:
    add_to_cart
    remove_from_cart (todo)
    purchase
*/
export var AnalyticsEventType;
(function (AnalyticsEventType) {
    AnalyticsEventType["Purchase"] = "purchase";
    AnalyticsEventType["AddToCart"] = "add_to_cart";
    AnalyticsEventType["UAAddToCart"] = "addToCart";
    AnalyticsEventType["UAPurchase"] = "purchase";
})(AnalyticsEventType || (AnalyticsEventType = {}));
class Item {
    constructor(item_id) {
        this.item_id = item_id;
    }
}
// Purchase
export class GAPurchase {
    constructor(transaction_id, affiliation, coupon, currency, items, shipping, value, tax) {
        this.transaction_id = transaction_id;
        this.affiliation = affiliation;
        this.coupon = coupon;
        this.currency = currency;
        this.items = items;
        this.shipping = shipping;
        this.value = value;
        this.tax = tax;
    }
}
export class GAPurchaseItem extends Item {
    constructor(item_id) {
        super(item_id);
    }
}
// Add to cart
export class GAAddToCart {
    constructor(items) {
        this.items = items;
    }
}
export class GAAddToCartItem extends Item {
    constructor(item_id) {
        super(item_id);
    }
}
// Shared
